import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Form, Input } from "reactstrap";
import Select from 'react-select';
import Pagination from "../../Jobs/JobList2/Pagination";
import { getAllCompany } from "../../../api/apiAccount";
import { GetCountryList } from "../../../api/apiCountry";
import PlaceholderLoaddingCompany from "../../../common/placeholder/PlaceholderLoaddingCompany";
//Import Job Images
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";

const CompanyDetails = () => {

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none",
    })
  };

  let companyRoute = '/company/';
  let [companes, setCompanes] = useState([]);
  let [totalPage, setTotalPage] = useState(1);
  let [name, setName] = useState('');
  let [countryId, setCountryId] = useState('');
  let [countryList, setCountryList] = useState();
  let [cityId, setCityId] = useState('');
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [flag, setFlag] = useState(0);
  let [alertNoData, setAlertNoData] = useState('');

  useEffect(() => {
    getAllCompany(1, 0, 0, "").then((result) => {
      setTotalPage(result.total);
      if (result.data.length) {
        const companesArr = [];
        result.data.map((item, key) => {
          companesArr.push({ avatar: item.avatar, fullName: item.fullName, userName: item.userName, cityId: item.cityId, about: removeFormatHtml(item.about) });
        })
        setCompanes(companesArr);
      }
    });

    GetCountryList().then((result) => {
      if (result.country) {
        const countryArr = [{ value: -1, label: 'Quốc gia' }, { value: 0, label: 'Tất cả' }];
        const cityArr = [{ value: -1, label: 'Thành phố' }];
        const cityListAllArr = [];
        result.country.map((item, key) => {
          setCountryId(countryArr[0]);
          setCityId(cityArr[0]);
          setCityList(cityArr);
          countryArr.push({ value: item.id, label: item.name });
          cityListAllArr.push(item.cities);
        })
        setCountryList(countryArr);
        setCityListAll(cityListAllArr);
      }
    });
  }, [])

  const removeFormatHtml = (about) => {
    const regex = /(<([^>]+)>)/gi;
    if (regex.test(about)) {
      const format = about.replace(regex, "");
      return format;
    } else {
      return "";
    }
  }

  function removeHtmlTags(input) {
    return input.replace(/<[^>]*>/g, '');
  }

  const loadCompanyData = (page, countryid, cityid, name) => {
    getAllCompany(page, countryid, cityid, name).then((result) => {
      setTotalPage(result.total);
      if (result.data && result.data.length) {
        const companesArr = [];
        result.data.map((item, key) => {
          companesArr.push({ avatar: item.avatar, fullName: item.fullName, userName: item.userName, cityId: item.cityId, about: removeFormatHtml(item.about) });
        })
        setCompanes(companesArr);
        if (companesArr.length == 0) {
          setAlertNoData('Không có kết quả tìm kiếm!');
        } else {
          setAlertNoData('');
        }
      } else {
        setAlertNoData('Không có kết quả tìm kiếm!');
        setCompanes([]);
      }
    })
  }

  const updateCurrentPage = (page) => {
    if (flag === 0) {
      loadCompanyData(page, 0, 0, "");
    } else {
      loadCompanyData(page, countryId.value, cityId.value, name);
    }
  }

  const handleName = event => {
    setName(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event);
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      const cityListArr = [{ value: -1, label: 'Thành phố' }];
      if (citieDropdown.length > 0) {
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
      if (event.value <= 0) {
        setCityId(cityListArr[0]);
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleFilter = () => {
    //save condition
    setFlag(1);
    loadCompanyData(1, countryId.value, cityId.value, name);
  }

  return (
    <React.Fragment>
      <Row className="align-items-center mb-4">
        <div className="me-lg-5">
          <div className="job-list-header">
            <Form action="#">
              <Row className="g-2">
                <Col lg={4} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-search me-1"></i>
                    <Input
                      value={name}
                      onChange={handleName}
                      type="search"
                      className="form-control filter-input-box"
                      id="exampleFormControlInput1"
                      placeholder="Tên công ty"
                      style={{ marginTop: "-10px" }}
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-location-point"></i>
                    <Select
                      options={countryList}
                      className="react-select-container choices selectForm__inner "
                      value={countryId}
                      onChange={handleCountry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-home-alt"></i>
                    <Select
                      options={cityList}
                      className="react-select-container choices selectForm__inner "
                      value={cityId}
                      onChange={handleCity}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={2} md={6}>
                  <Link onClick={() => handleFilter()} className="btn btn-primary w-100">
                    Tìm kiếm
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Row>

      <Row>
        {alertNoData && <div className="section-title text-center">
          <h3 className="title">{alertNoData}</h3>
        </div>}
        {(!companes.length && !alertNoData) ? (<PlaceholderLoaddingCompany />) : companes.map((companesDetail, key) => (
          <Col lg={4} md={6} key={key}>
            <Card className="text-center mb-4">
              <CardBody className="px-4 py-5" style={{ height: '409.6px' }}>
                <Link to={companyRoute + companesDetail.userName}>
                  <img
                    src={companesDetail.avatar ? companesDetail.avatar : ImageDefault}
                    alt=""
                    className="img-fluid rounded-3"
                    style={{ height: '100px', width: '100px', objectFit: 'cover' }}
                  />
                </Link>
                <div className="mt-4">
                  <Link to={companyRoute + companesDetail.userName} className="primary-link">
                    <h6 className="fs-18 mb-2">
                      {companesDetail.fullName}
                    </h6>
                  </Link>
                  <div className="about-company mb-4"  style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: companesDetail.about }} />
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetails;
