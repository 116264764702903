import React from "react";
import { Card, CardBody, Col, Placeholder } from "reactstrap";

const PlaceholderLoaddingCompany = () => {
    const LoaddingCompany = [
        { text: 'company1', value: 'value1' },
        { text: 'company2', value: 'value2' },
        { text: 'company3', value: 'value3' },
        { text: 'company4', value: 'value4' },
        { text: 'company5', value: 'value5' },
        { text: 'company6', value: 'value6' },
        { text: 'company7', value: 'value7' },
        { text: 'company8', value: 'value8' },
        { text: 'company9', value: 'value9' },
    ];
    
    return (
        <React.Fragment>
            {LoaddingCompany.map((item, key) => (
                <Col lg={4} md={6} key={key}>
                    <Card className="text-center mb-4">
                        <CardBody className="px-4 py-5" style={{ height: '409.6px' }}>
                            <Placeholder animation="glow">
                                <Placeholder className="img-fluid rounded-3" style={{ height: '100px', width: '100px' }} color="secondary" size="lg" xs={12} />
                            </Placeholder>
                            <div className="mt-4">
                                <h6 className="fs-18 mb-2">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={3} />
                                    </Placeholder>
                                </h6>
                                <Placeholder animation="glow">
                                    <div className="about-company mb-4">
                                        <Placeholder color="secondary" xs={12} />
                                        <Placeholder color="secondary" xs={12} />
                                        <Placeholder color="secondary" xs={12} />
                                        <Placeholder color="secondary" xs={12} />
                                        <Placeholder color="secondary" xs={12} />
                                    </div>
                                </Placeholder>
                            </div>
                        </CardBody>
                    </Card>
                </Col>)
            )}
        </React.Fragment>
    );
};

export default PlaceholderLoaddingCompany;