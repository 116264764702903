import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Section from "../../Jobs/JobList/Section";
import JobVacancyList from "./JobVacancyList";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const JobList = () => {
  const { t } = useTranslation("translation");

  document.title = t("managejob.danhsachcongviec");
  let { state, pathname } = useLocation();

  let [paramSearch, setParamSearch] = useState();

  useEffect(() => {
    let params = pathname.split('/');
    let data = { name: state ? state.name : '', countryid: {value: params[2], label: ''}, industry: {value: params[3], label: ''}};
    setParamSearch(data);
  }, [])

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            {paramSearch && <JobVacancyList data={paramSearch} />}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobList;
