import React, { useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import homepage from "../../../assets/images/ungvien/homepage.webp";
import step1 from "../../../assets/images/ungvien/step1.webp";
import step2 from "../../../assets/images/ungvien/step2.webp";
import step3 from "../../../assets/images/ungvien/step3.webp";
import step4 from "../../../assets/images/ungvien/step4.webp";
import step5 from "../../../assets/images/ungvien/step5.webp";
import step6 from "../../../assets/images/ungvien/step6.webp";
import extension1 from "../../../assets/images/ungvien/extension1.webp";
import extension2 from "../../../assets/images/ungvien/extension2.webp";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import '../../ExtraPages/AboutCompany.css';

const SectionEmployee = () => {
  document.title = "TopJob360 - Ứng viên";
  const { t } = useTranslation("translation");

  const keyFeatures = [
    {
      id: 1,
      title: "Cơ hội việc làm đa dạng:",
      content: "Khám phá hàng ngàn tin tuyển dụng hấp dẫn từ các công ty uy tín trong và ngoài nước, thuộc nhiều lĩnh vực như Công nghệ, Kinh doanh, Marketing, Nhân sự, v.v.",
      imageFeatures: step1
    },
    {
      id: 2,
      title: "Tìm kiếm dễ dàng:",
      content: "Sử dụng bộ lọc thông minh để nhanh chóng tìm kiếm các vị trí phù hợp với sở thích, kỹ năng và kinh nghiệm của bạn.",
      imageFeatures: step2
    },
    {
      id: 3,
      title: "Hồ sơ ứng tuyển ấn tượng:",
      content: "Tạo dựng hồ sơ ứng tuyển chuyên nghiệp với các công cụ hỗ trợ hiện đại, giúp bạn nổi bật giữa đám đông ứng viên.",
      imageFeatures: step3
    },
    {
      id: 4,
      title: "Cập nhật thường xuyên:",
      content: "Nhận thông báo qua email về các tin tuyển dụng mới nhất phù hợp với nhu cầu của bạn.",
      imageFeatures: step4
    },
    {
      id: 5,
      title: "Hỗ trợ tận tình:",
      content: "Đội ngũ chuyên viên tư vấn giàu kinh nghiệm luôn sẵn sàng hỗ trợ bạn trong suốt quá trình tìm kiếm việc làm.",
      imageFeatures: step5
    },
    {
      id: 6,
      title: "Hỗ trợ xin visa làm việc:",
      content: "Hướng dẫn và hỗ trợ các thủ tục xin visa làm việc tại các quốc gia khác nhau.",
      imageFeatures: step6
    },
  ];

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <React.Fragment>
      <div className="aboutus">
        <div className="introduce" style={{ backgroundImage: `url(${homepage})` }}>
        </div>
        <Container>
          <div data-aos="fade-down">
            <div className="footer-about-slogan mb-5 mt-5">
              <h2>Cơ hội việc làm tại nước ngoài với TopJob360</h2>
            </div>
          </div>
          <div data-aos="fade-left">
            <h5 className="text-center mb-3 title-candidate">Bạn đang muốn tìm việc làm lương cao ở nước ngoài?</h5>
          </div>
          <div data-aos="fade-right">
            <h5 className="text-center mb-3 title-candidate">Bạn đang ấp ủ ước mơ chinh phục những đỉnh cao mới trong sự nghiệp tại các công ty đa quốc gia?</h5>
          </div>
          <div data-aos="fade-left">
            <h5 className="text-center mb-3 title-candidate">Bạn mong muốn trải nghiệm môi trường làm việc chuyên nghiệp và năng động, đồng thời hưởng mức lương hấp dẫn?</h5>
          </div>
          <div data-aos="fade-up">
            <div className="footer-about-slogan mt-5">
              <h2>Vậy thì TopJob360 chính là dành cho bạn!</h2>
            </div>
          </div>
          <div data-aos="fade-down">
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="text-center" style={{ marginLeft: '24px' }}>
                  <div className="video-embed video-introduce">
                    <iframe
                      src="https://www.youtube.com/embed/2gRbn1u5Wis?si=wATcV2C8eQDPpm53&rel=0"
                      title="YouTube video player" loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up">
            <h5 className="text-center mb-3 title-candidate">TopJob360 là cổng thông tin tuyển dụng uy tín hàng đầu dành cho ứng viên Việt Nam tìm kiếm cơ hội việc làm tại các công ty nước ngoài.</h5>
          </div>
          <div data-aos="fade-up">
            <h5 className="text-center mb-3 title-candidate">Chúng tôi cung cấp đa dạng các vị trí tuyển dụng trong nhiều lĩnh vực, ngành nghề, phù hợp với mọi trình độ kinh nghiệm và chuyên môn.</h5>
          </div>
          <section className="section bg-light">
            <Container>
              <Row className="justify-content-center" data-aos="fade-down">
                <Col lg={6}>
                  <div className="section-title text-center mb-5">
                    <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>Tại sao bạn nên lựa chọn <span style={{ color: "#027F52" }}>TopJob360</span>?</h5>
                  </div>
                </Col>
              </Row>
              {
                keyFeatures.map((features, key) => (
                  <div className="job-box card mt-2" data-aos="fade-down" key={key}>
                    <Row className="align-items-center">
                      <Col lg={6}>
                        <img src={features.imageFeatures} alt="" className="img-fluid" />
                      </Col>
                      <Col lg={6}>
                        <h5 className="fs-18">{features.title}</h5>
                        <div className="description-list">
                          <p>{features.content}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              }
              <Row className="justify-content-center" data-aos="fade-up">
                <Col lg={6}>
                  <div className="section-title text-center mt-5">
                    <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>Hãy gia nhập cộng đồng <span className="title-candidate">TopJob360</span> ngay hôm nay để mở rộng cánh cửa cơ hội nghề nghiệp của bạn!</h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <div data-aos="fade-up">
            <h5 className="text-center mt-3 mb-3" style={{ fontSize: '24px' }}>Truy cập <span className="title-candidate">https://TopJob360.com</span> để bắt đầu hành trình chinh phục ước mơ của bạn!</h5>
          </div>
          <section className="section bg-light">
            <Container>
              <div className="section-title me-5" data-aos="fade-right">
                <h5>Ngoài những lợi ích trên, <span style={{ color: "#027F52" }}>TopJob360</span> còn mang đến cho bạn:</h5>
              </div>
              <Row className="align-items-center" data-aos="fade-up">
                <Col lg={1} md={6}></Col>
                <Col lg={5} md={6}>
                  <div className="blog-box card p-2 mt-3" style={{ height: '400px' }}>
                    <div className="blog-img position-relative overflow-hidden">
                      <img src={extension1} alt="" className="img-fluid" />
                      <div className="bg-overlay"></div>
                    </div>
                    <div className="card-body">
                      <Link className="primary-link">
                        <h5 className="fs-17">Cơ hội tham gia các hội thảo và sự kiện nghề nghiệp:</h5>
                      </Link>
                      <p className="text-muted">Nâng cao kiến thức và kỹ năng, mở rộng network và kết nối với các nhà tuyển dụng tiềm năng.</p>
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={6}>
                  <div className="blog-box card p-2 mt-3" style={{ height: '400px' }}>
                    <div className="blog-img position-relative overflow-hidden">
                      <img src={extension2} alt="" className="img-fluid" />
                      <div className="bg-overlay"></div>
                    </div>
                    <div className="card-body">
                      <Link className="primary-link">
                        <h5 className="fs-17">Thông tin thị trường lao động:</h5>
                      </Link>
                      <p className="text-muted">Cập nhật những xu hướng mới nhất trong lĩnh vực tuyển dụng và nhân sự, giúp bạn định hướng nghề nghiệp hiệu quả.</p>
                    </div>
                  </div>
                </Col>
                <Col lg={1} md={6}></Col>
              </Row>
            </Container>
          </section>
          <div data-aos="fade-up">
            <h5 className="text-center mt-5 title-candidate">Thế giới rộng lớn, cơ hội vô cùng, hãy để TopJob360 kết nối bạn với những cơ hội việc làm quốc tế ở những công ty uy tín toàn cầu, cùng nhau chinh phục thành công.</h5>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SectionEmployee;