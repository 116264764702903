import React from "react";
import PageHeader from "../../Component/PageHeader"
import { useTranslation } from "react-i18next";
import { APP_CONST } from "../../../common/constants/Constants";
import { Container, Row, Col } from "reactstrap";
const PrivacyAndPolicy = () => {
  const { t } = useTranslation("translation");
  let title = t("policy.title");
  document.title = title+" - " + APP_CONST.APP_NAME;
  return (
    <React.Fragment>
      <PageHeader title={title} />
      <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
          <Col lg={12}>
            <p>{t("policy.l1")}</p>
            <p>{t("policy.l2")}</p>
            
            <h4 className="mb-3 mt-5">{t("policy.l3")}</h4>
            <p>{t("policy.l4",{ 'interpolation': {'escapeValue': true} })}</p>
            <h4 className="mb-3 mt-5">{t("policy.l5")}</h4>
            <h5>{t("policy.l6")}</h5>
            <p>{t("policy.l7")}</p>
            <h5>{t("policy.l8")}</h5>
            <p>{t("policy.l9")}</p>
            <p>{t("policy.l10")}</p>
            <h5>{t("policy.l11")}</h5>
            <p>{t("policy.l12")}</p>
            <h5>{t("policy.l13")}</h5>
            <p>{t("policy.l14")}</p>
            <ul className="about-list list-unstyled text-muted mb-2 pb-2">
              <li>{t("policy.l15")}</li>
              <li>{t("policy.l16")}</li>
              <li>{t("policy.l17")}</li>
              <li>{t("policy.l18")}</li>
              <li>{t("policy.l19")}</li>
              <li>{t("policy.l20")}</li>
              <li>{t("policy.l21")}</li>
              <li>{t("policy.l22")}</li>
              <li>{t("policy.l23")}</li>
              <li>{t("policy.l24")}</li>
            </ul>
            <h4 className="mb-3 mt-5">{t("policy.l25")}</h4>
            <p>{t("policy.l26")}</p>
            <p>{t("policy.l27")}</p>
            <p>{t("policy.l28")}</p>
            <p>{t("policy.l29")}</p>
            <ul className="about-list list-unstyled text-muted mb-2 pb-2">
              <li>{t("policy.l30")}</li>
              <li>{t("policy.l31")}</li>
              <li>{t("policy.l32")}</li>
              <li>{t("policy.l33")}</li>
            </ul>
            <h4 className="mb-3 mt-5">{t("policy.l34")}</h4>
            <p>{t("policy.l35")}</p>
            <p>{t("policy.l36")}</p>
            <p>{t("policy.l37")}</p>
            <p>{t("policy.l38")}</p>
            <h4 className="mb-3 mt-5">{t("policy.l39")}</h4>
            <p>{t("policy.l40")}</p>
            <h4 className="mb-3 mt-5">{t("policy.l41")}</h4>
            <p>{t("policy.l42")}</p>
          </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
    </React.Fragment>
  );
};
export default PrivacyAndPolicy;
