import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Pagination = (props) => {

  let [itemPage, setItemPage] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(props.totalPage / 20); i++) {
      pageNumbers.push(i);
    }
    setItemPage(pageNumbers);

  }, [props]);

  const updatePageNumber = (item) => {
    props.currentPage(item);
    setCurrentPage(item);
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className="mt-4 pt-2">
          <nav aria-label="Page navigation example">
            <div className="pagination job-pagination mb-0 justify-content-center">
              <li className="page-item">
                <Link className="page-link" onClick={() => updatePageNumber(1)} tabIndex="-1">
                  <i className="mdi mdi-chevron-double-left fs-15"></i>
                </Link>
              </li>
              {
                itemPage && itemPage.map((item, key) => {
                  if (currentPage == item) {
                    return (
                      <li className="page-item active" key={key}>
                        <Link className="page-link" onClick={() => updatePageNumber(item)}>
                          {item}
                        </Link>
                      </li>
                    )
                  } else {
                    return (
                      <li className="page-item" key={key}>
                        <Link className="page-link" onClick={() => updatePageNumber(item)}>
                          {item}
                        </Link>
                      </li>
                    )
                  }
                })
              }
              <li className="page-item">
                <Link className="page-link" onClick={() => updatePageNumber(itemPage.length)}>
                  <i className="mdi mdi-chevron-double-right fs-15"></i>
                </Link>
              </li>
            </div>
          </nav>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Pagination;
