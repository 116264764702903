import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Card, Col, Row, CardBody } from "reactstrap";
import ScrolltoTop from "../../components/ScrolltoTop";
import { useTranslation } from "react-i18next";
import { APP_CONST } from "../../common/constants/Constants";
import imageCandidate from "../../assets/images/candidate.webp";
import imageEmployee from "../../assets/images/employee.webp";

const SignUp = () => {
  document.title = APP_CONST.APP_NAME;
  const { t } = useTranslation("translation");

  const navigate = useNavigate();

  const handleSignUpCandidate = () => {
    navigate("/signup/candidate");
  };

  const handleSignUpEmployee = () => {
    navigate("/signup/employee");
  };

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>

                    <Card className="auth-box">
                      <div className="text-center" style={{ marginTop: 40, color: "#027F52", }}>
                        <h6 style={{ marginBottom: '6px', fontSize: '18px', fontWeight: 'bold' }}>{t("signup.chonnhomphuhop1")}</h6>
                        <h6 style={{ marginTop: '6px', fontSize: '18px', fontWeight: 'bold' }}>{t("signup.chonnhomphuhop2")}</h6>
                      </div>
                      <Row className="align-items-center px-4">
                        <Col lg={6} className="text-center">
                          <CardBody
                            style={{ border: "2px solid #027F52", borderRadius: '24px', padding: '16px', margin: '30px 4px' }}>
                            <Link to="#">
                              <img
                                src={imageCandidate}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={imageCandidate}
                                alt=""
                                className="logo-dark w-100"
                              />
                            </Link>
                            <div className="text-center">
                              <button
                                className="btn btn-primary"
                                style={{ borderRadius: '20px' }}
                                onClick={handleSignUpCandidate}
                              >
                                {t("signup.nhatuyendung")}
                              </button>
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody
                            style={{ border: "2px solid #027F52", borderRadius: '24px', padding: '16px', margin: '30px 4px' }}>
                            <Link to="#">
                              <img
                                src={imageEmployee}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={imageEmployee}
                                alt=""
                                className="logo-dark w-100"
                              />
                            </Link>
                            <div className="text-center">
                              <button
                                className="btn btn-primary"
                                style={{ borderRadius: '20px' }}
                                onClick={handleSignUpEmployee}
                              >
                                {t("signup.ungvientimviec")}
                              </button>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <ScrolltoTop />
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
