import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SignUpCandidateContent from "./SignUpCandidateContent";
import { siteKey } from "../../../configs/url";

const SignUpCandidate = () => {

  return (
    <React.Fragment>
      <GoogleReCaptchaProvider
        reCaptchaKey={siteKey}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <SignUpCandidateContent />
      </GoogleReCaptchaProvider>
    </React.Fragment>
  );
};

export default SignUpCandidate;