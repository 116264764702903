import React from "react";
import { Row, Col, Placeholder } from "reactstrap";

const PlaceholderJobFilter = () => {
    return (
        <React.Fragment>
            <div className="job-box card mt-4">
                <div className="bookmark-label text-center">
                    <Placeholder animation="glow">
                        <Placeholder color="secondary" xs={12} />
                    </Placeholder>
                </div>
                <div className="p-4">
                    <Row className="align-items-center">
                        <Col md={2}>
                            <div className="text-center mb-4 mb-md-0">
                                <Placeholder animation="glow">
                                    <Placeholder className="img-fluid rounded-3" style={{ height: 50, width: 50 }} color="secondary" size="lg" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-2 mb-md-0">
                                <h5 className="fs-18 mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </h5>
                                <p className="text-muted fs-14 mb-0"></p>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-3 bg-light">
                    <Row className="justify-content-between">
                        <Col md={4}>
                            <div>
                                <p className="text-muted mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} md={3}>
                            <div className="text-start text-md-end">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="job-box card mt-4">
                <div className="bookmark-label text-center">
                    <Placeholder animation="glow">
                        <Placeholder color="secondary" xs={12} />
                    </Placeholder>
                </div>
                <div className="p-4">
                    <Row className="align-items-center">
                        <Col md={2}>
                            <div className="text-center mb-4 mb-md-0">
                                <Placeholder animation="glow">
                                    <Placeholder className="img-fluid rounded-3" style={{ height: 50, width: 50 }} color="secondary" size="lg" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-2 mb-md-0">
                                <h5 className="fs-18 mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </h5>
                                <p className="text-muted fs-14 mb-0"></p>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-3 bg-light">
                    <Row className="justify-content-between">
                        <Col md={4}>
                            <div>
                                <p className="text-muted mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} md={3}>
                            <div className="text-start text-md-end">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="job-box card mt-4">
                <div className="bookmark-label text-center">
                    <Placeholder animation="glow">
                        <Placeholder color="secondary" xs={12} />
                    </Placeholder>
                </div>
                <div className="p-4">
                    <Row className="align-items-center">
                        <Col md={2}>
                            <div className="text-center mb-4 mb-md-0">
                                <Placeholder animation="glow">
                                    <Placeholder className="img-fluid rounded-3" style={{ height: 50, width: 50 }} color="secondary" size="lg" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-2 mb-md-0">
                                <h5 className="fs-18 mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </h5>
                                <p className="text-muted fs-14 mb-0"></p>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div>
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-3 bg-light">
                    <Row className="justify-content-between">
                        <Col md={4}>
                            <div>
                                <p className="text-muted mb-0">
                                    <Placeholder animation="glow">
                                        <Placeholder color="secondary" xs={12} />
                                    </Placeholder>
                                </p>
                            </div>
                        </Col>
                        <Col lg={2} md={3}>
                            <div className="text-start text-md-end">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                </Placeholder>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </React.Fragment>
    );
};

export default PlaceholderJobFilter;