import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const AlertsSuccessAvatar = (props) => {
  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
    window.location.reload();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered fade={true} backdrop="static">
        <ModalBody>
          <div className='d-flex justify-content-between align-items-center' style={{ flexDirection: 'column' }}>
            <div className='check-background' style={{ margin: '18px 0' }}>
              <svg viewBox="0 0 65 51" fill="none">
                <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <h5 className='mt-2' style={{ color: '#028C5D', fontWeight: '600', fontSize: '24px' }}>Success</h5>
            <p className='mt-3' style={{ fontSize: '18px' }}>{props.message}</p>
            <button className='btn w-100 mt-3'
              style={{ backgroundColor: '#028C5D', color: 'white', fontWeight: '600', maxHeight: '48px' }}
              onClick={toggle}
            >
              Ok
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};


export default AlertsSuccessAvatar;