import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  NavItem,
  CardBody,
  Label
} from "reactstrap";

import classnames from "classnames";


import { createJob } from "../../../api/apiJob";
import Alerts from "../../ExtraPages/Components/Alerts";
import AlertsSuccess from "../../ExtraPages/Components/AlertsSuccess";
import Spinners from "../../ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

const JobContent = () => {
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navigate = useNavigate();

  const didMount = useRef(false);

  const { t } = useTranslation("translation");

  const countryList = [
    { value: "1", label: "Việt Nam" },
    { value: "2", label: "Nhật Bản" },
    { value: "3", label: "Anh" },
    { value: "4", label: "Pháp" },
    { value: "5", label: "Đức" },
  ];

  const jobTypeList = [
    { value: "0", label: "Toàn thời gian" },
    { value: "1", label: "Bán thời gian" },
  ];

  const vocativeList = [
    { value: "Mr.", label: "Mr" },
    { value: "Ms.", label: "Ms" },
  ];

  const genderList = [
    { value: "0", label: "Không yêu cầu" },
    { value: "1", label: "Nam" },
    { value: "2", label: "Nữ" },
    { value: "3", label: "Khác" },
  ];

  const experienceRequiredList = [
    { value: "0", label: "Chưa có kinh nghiệm" },
    { value: "1", label: "1 năm kinh nghiệm" },
    { value: "2", label: "2 năm kinh nghiệm" },
    { value: "3", label: "3 năm kinh nghiệm" },
  ];

  const currencyList = [
    { value: "VND", label: "VND" },
    { value: "JPY", label: "JPY" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
  ];

  let date = Moment(new Date).format("YYYY-MM-DD");

  let [title, setTitle] = useState('');
  let [jobType, setJobType] = useState('');
  let [gender, setGender] = useState(0);
  let [experienceRequired, setExperienceRequired] = useState(0);
  let [position, setPosition] = useState('');
  let [workLocationAddress, setWorkLocationAddress] = useState('');
  let [totalWorkerNeeded, setTotalWorkerNeeded] = useState(0);
  let [salaryFrom, setSalaryFrom] = useState(0);
  let [salaryTo, setSalaryTo] = useState(0);
  let [currency, setCurrency] = useState('VND');
  let [dateNeeded, setDateNeeded] = useState(date);
  let [jobDescription, setJobDescription] = useState('');
  let [jobRequired, setJobRequired] = useState('');

  let [countryId, setCountryId] = useState(1);

  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);
  let [invalidEdit, setInvalidEdit] = useState(false);
  let [disableProfile, setDisableProfile] = useState(true);

  const handleTitle = event => {
    setTitle(event.target.value);
  }

  const handleJobType = event => {
    setJobType(event.target.value);
  }

  const handleGender = event => {
    setGender(event.target.value);
  }

  const handleExperienceRequired = event => {
    setExperienceRequired(event.target.value);
  }

  const handlePosition = event => {
    setPosition(event.target.value);
  }

  const handleTotalWorkerNeeded = event => {
    setTotalWorkerNeeded(event.target.value);
  }

  const handleSalaryFrom = event => {
    setSalaryFrom(event.target.value);
  }

  const handleSalaryTo = event => {
    setSalaryTo(event.target.value);
  }

  const handleCurrency = event => {
    setCurrency(event.target.value);
  }

  const handleDateNeeded = event => {
    setDateNeeded(event.target.value);
  }

  const handleJobDescription = event => {
    setJobDescription(event.target.value);
  }

  const handleJobRequired = event => {
    setJobRequired(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event.target.value);
  }

  const handleWorkLocationAddress = event => {
    setWorkLocationAddress(event.target.value);
  }

  const background_color = disableProfile ? '#C0C0C0' : '#FFFFFF';

  
  const handleEdit = () => {
    setDisableProfile(false);
    setInvalidEdit(true);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('title');
  }, [title]);

  const checkValidate = (value) => {
    !title ? setInvalid(true) : setInvalid(false);
  }

  const handleCreateJob = () => {
    let job = {
      title: title,
      jobType: Number(jobType),
      gender: Number(gender),
      experienceRequired: Number(experienceRequired),
      position: position,
      totalWorkerNeeded: Number(totalWorkerNeeded),
      workLocationAddress: workLocationAddress,
      currency: currency,
      salaryFrom: Number(salaryFrom),
      salaryTo: Number(salaryTo),
      dateNeeded: dateNeeded,
      jobDescription: jobDescription,
      jobRequired: jobRequired
    }
    setAlertDanger(false);
    setAlertSuccess(false);
    setLoading(true);

    createJob(job).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setAlertSuccess(true);
        setAlertDanger(false);
      } else {
        setLoading(false);
        setAlertDanger(true);
        setAlertSuccess(false);
      }
    }, (error) => {
      setLoading(false);
      setAlertDanger(true);
      setAlertSuccess(false);
    });
  };

  const handleView = () => {
    navigate("/joblist");
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          {loading && <Spinners />}
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                Thông tin chung
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-17 fw-semibold mb-3 mb-0">Nhập thông tin đơn tuyển</h5>
                  <Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                          Tên công việc
                        </label>
                        <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="title"
                          onChange={handleTitle}
                          value={title}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="position" className="form-label">
                          Vị trí
                        </label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="position"
                          onChange={handlePosition}
                          value={position}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="totalWorkerNeeded" className="form-label">
                          Tổng số người cần làm việc
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="number"
                          className="form-control"
                          id="totalWorkerNeeded"
                          onChange={handleTotalWorkerNeeded}
                          value={totalWorkerNeeded}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-categories"
                          className="form-label"
                        >
                          Quốc gia
                        </label>
                        <select
                          style={{ backgroundColor: background_color }}
                          className="form-select"
                          data-trigger
                          name="choices-country"
                          id="choices-country"
                          aria-label="Default select country"
                          onChange={handleCountry}
                          defaultValue={countryId}
                          disabled={disableProfile}
                        >
                          {
                            countryList.map((countryDetail, key) => (
                              <option key={countryDetail.value} value={countryDetail.value}>{countryDetail.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="workLocationAddress" className="form-label">
                          Địa chỉ làm việc
                        </label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="workLocationAddress"
                          onChange={handleWorkLocationAddress}
                          value={workLocationAddress}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <Row style={{ width: '100%', marginLeft: -1 }}>
                        <Label htmlFor="salary" className="form-label" style={{ marginLeft: -13 }} >
                          Mức lương
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color, width: '35%' }}
                          type="number"
                          className="form-control"
                          id="salaryFrom"
                          onChange={handleSalaryFrom}
                          value={salaryFrom}
                          disabled={disableProfile}
                        />
                        <Input
                          style={{ backgroundColor: background_color, width: '35%' }}
                          type="number"
                          className="form-control"
                          id="salaryTo"
                          onChange={handleSalaryTo}
                          value={salaryTo}
                          disabled={disableProfile}
                        />
                        <select
                          style={{ backgroundColor: background_color, width: '30%' }}
                          className="form-select"
                          data-trigger
                          name="choices-currency"
                          id="choices-currency"
                          aria-label="Default select currency"
                          onChange={handleCurrency}
                          defaultValue={currency}
                          disabled={disableProfile}
                        >
                          {
                            currencyList.map((currencyDetail, key) => (
                              <option key={currencyDetail.value} value={currencyDetail.value}>{currencyDetail.label}</option>
                            ))
                          }
                        </select>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="dateNeeded"
                          className="form-label"
                        >
                          Thời gian cần
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="date"
                          className="form-control"
                          id="dateNeeded"
                          onChange={handleDateNeeded}
                          value={dateNeeded}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="jobType" className="form-label">
                          Loại công việc
                        </label>
                        <select
                          style={{ backgroundColor: background_color }}
                          className="form-select"
                          data-trigger
                          name="choices-jobType"
                          id="choices-jobType"
                          aria-label="Default select jobType"
                          onChange={handleJobType}
                          defaultValue={jobType}
                          disabled={disableProfile}
                        >
                          {
                            jobTypeList.map((jobTypeDetail, key) => (
                              <option key={jobTypeDetail.value} value={jobTypeDetail.value}>{jobTypeDetail.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="gender" className="form-label">
                          Giới tính
                        </Label>
                        <select
                          style={{ backgroundColor: background_color }}
                          className="form-select"
                          data-trigger
                          name="choices-gender"
                          id="choices-gender"
                          aria-label="Default select gender"
                          onChange={handleGender}
                          defaultValue={gender}
                          disabled={disableProfile}
                        >
                          {
                            genderList.map((genderDetail, key) => (
                              <option key={genderDetail.value} value={genderDetail.value}>{genderDetail.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="experienceRequired" className="form-label">
                          Kinh nghiệm
                        </Label>
                        <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                        <select
                          style={{ backgroundColor: background_color }}
                          className="form-select"
                          data-trigger
                          name="choices-experienceRequired"
                          id="choices-experienceRequired"
                          aria-label="Default select experienceRequired"
                          onChange={handleExperienceRequired}
                          defaultValue={experienceRequired}
                          disabled={disableProfile}
                        >
                          {
                            experienceRequiredList.map((experienceRequiredDetail, key) => (
                              <option key={experienceRequiredDetail.value} value={experienceRequiredDetail.value}>{experienceRequiredDetail.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </Col>
                    <Col lg={7}>
                      <div className="mb-3">
                        <Label htmlFor="jobDescription" className="form-label">
                          Mô tả công việc
                        </Label>
                        <textarea
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="jobDescription"
                          onChange={handleJobDescription}
                          value={jobDescription}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={7}>
                      <div className="mb-3">
                        <Label htmlFor="jobRequired" className="form-label">
                          Yêu cầu công việc
                        </Label>
                        <textarea
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="jobRequired"
                          onChange={handleJobRequired}
                          value={jobRequired}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4 text-end">
                  <button
                    className="btn btn-danger"
                    onClick={handleEdit}
                    disabled={invalidEdit}
                  >
                    Sửa
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleCreateJob}
                    disabled={invalid}
                  >
                    Cập nhật
                  </button>
                  <button
                    className="btn btn-detail"
                    onClick={handleView}
                  >
                    Danh sách việc
                  </button>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
        {alertdanger && <Alerts message={MESSAGE_VALIDATE.update_profile_false} />}
      </Col>
    </React.Fragment >
  );
};

export default JobContent;
