export const MESSAGE_VALIDATE = {
    login_invalid: 'Tài khoản hoặc mật khẩu không chính xác!',
    singup_invalid: 'Thông tin đăng ký không hợp lệ!',
    singup_duplicate_email: 'Email đã được đăng ký',
    change_pass: 'Thông tin không hợp lệ!',
    forgot_pass: 'Thông tin không hợp lệ!',
    update_avatar_success: 'Cập nhật Avatar thành công',
    update_profile_success: 'Cập nhật thông tin thành công',
    update_profile_fail: 'Cập nhật thông tin không thành công',
    update_profile_validate: 'Vui lòng nhập tên trường hoặc tên công ty!',
    apply_job_success: 'Ứng tuyển thành công',
    apply_job_fail: 'Ứng tuyển không thành công',
    apply_job_login: 'Vui lòng đăng nhập để ứng tuyển',
    apply_job_checkfile: 'Vui lòng upload file để ứng tuyển'
  };