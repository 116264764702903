import React, { useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../../common/constants/Storage';
import { setStorage, getStorage } from '../../../common/constants/Services';
import { MESSAGE_VALIDATE } from '../../../common/constants/Messages';
import { login, getAccountInfo } from '../../../api/apiAccount';
import { Link, useNavigate } from "react-router-dom";

const AdminLogin = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState();

  const handleLogin = () => {
    login(email, password).then((res) => {
      if (res.accessToken) {
        setStorage(STORAGE_KEYS.ACCESS_TOKEN, res.accessToken);
        setStorage(STORAGE_KEYS.REFRESH_TOKEN, res.refreshToken);
        let expiredTime = new Date();
        const seconds = expiredTime.getSeconds() + res.expiresIn;
        expiredTime.setSeconds(seconds);
        setStorage(STORAGE_KEYS.EXPIRED_TOKEN, expiredTime.getTime());
        getAccountInfo().then((result) => {
          if (result.role == "SystemAdmin" ) {
            setStorage(STORAGE_KEYS.ROLE, result.role);
            navigate("/quanly");
          }
          else{
            setNotification("Không có quyền truy cập !!!")
          }
        }).catch((error) => {
          console.error("Lỗi khi lấy thông tin tài khoản:", error);
        });
      }
      else {
        setNotification("Sai tên đăng nhập hoặc mật khẩu")
      }
    })
  }


  return (
    <div className='d-flex justify-content-center align-items-center w-100'
      style={{ padding: '24px 8px', borderRadius: '12px', marginTop: '80px' }}
    >
      <div>
        <h1>Đăng nhập - Admin</h1>
        <div className='d-flex flex-column mb-2'>
          <label htmlFor="username">Email:</label>
          <input
            type="text"
            id="email"
            value={email}
            placeholder='Nhập email'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='d-flex flex-column mb-2'>
          <label htmlFor="password">Mật khẩu:</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder='Nhập mật khẩu'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <p className='text-danger'>{notification}</p>
        <button onClick={handleLogin} type='submit' className='btn btn-dark'>Đăng nhập</button>
      </div>
    </div>
  );
};

export default AdminLogin;