import React, { useState, useEffect } from "react";
import {
    Modal, ModalBody, Input,
} from "reactstrap";
import { getMyFile } from "../../../api/apiAccount";
import { applyJob } from "../../../api/apiJob";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";

const ApplyJobPopup = ({ modalState, toggleModal, jobId, message }) => {

    const token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    const role = getStorage(STORAGE_KEYS.ROLE);
    let [hscv, setHSCV] = useState(false);
    let [disabledHSCV, setDisabledHSCV] = useState(true);
    let [btn, setBTN] = useState(false);
    let [disabledBTN, setDisabledBTN] = useState(true);
    let [bdtn, setBDTN] = useState(false);
    let [disabledBDTN, setDisabledBDTN] = useState(true);
    let [ccnn, setCCNN] = useState(false);
    let [disabledCCNN, setDisabledCCNN] = useState(true);
    let [hshc, setHSHC] = useState(false);
    let [disabledHSHC, setDisabledHSHC] = useState(true);
    let [tct, setTCT] = useState(false);
    let [disabledTCT, setDisabledTCT] = useState(true);
    let [hsblx, setHSBLX] = useState(false);
    let [disabledHSBLX, setDisabledHSBLX] = useState(true);
    let [ccta, setCCTA] = useState(false);
    let [disabledCCTA, setDisabledCCTA] = useState(true);
    let [cctn, setCCTN] = useState(false);
    let [disabledCCTN, setDisabledCCTN] = useState(true);
    let [ccth, setCCTH] = useState(false);
    let [disabledCCTH, setDisabledCCTH] = useState(true);

    let [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        if (jobId && token && role === 'Employee') {
            getMyFile().then((res) => {
                res.map((value, key) => {
                    if (value.fileTypeId == 1) { setDisabledHSCV(false) };
                    if (value.fileTypeId == 2) { setDisabledBTN(false) };
                    if (value.fileTypeId == 3) { setDisabledBDTN(false) };
                    if (value.fileTypeId == 4) { setDisabledCCNN(false) };
                    if (value.fileTypeId == 5) { setDisabledHSHC(false) };
                    if (value.fileTypeId == 6) { setDisabledTCT(false) };
                    if (value.fileTypeId == 7) { setDisabledHSBLX(false) };
                    if (value.fileTypeId == 8) { setDisabledCCTA(false) };
                    if (value.fileTypeId == 9) { setDisabledCCTN(false) };
                    if (value.fileTypeId == 10) { setDisabledCCTH(false) };
                });

            });
        }
    }, [])

    const getValueCheckbox = (checked, filetype) => {
        if (checked) {
            setDataSelected([...dataSelected, filetype])
        } else {
            if (dataSelected.length > 0) {
                let dataFilter = dataSelected.filter(d => d !== filetype)
                setDataSelected(dataFilter);
            }
        }
    }
    const handleChangeHSCV = event => {
        setHSCV(event.target.checked);
        getValueCheckbox(event.target.checked, 1);
    }

    const handleChangeBTN = event => {
        setBTN(event.target.checked);
        getValueCheckbox(event.target.checked, 2);
    }

    const handleChangeBDTN = event => {
        setBDTN(event.target.checked);
        getValueCheckbox(event.target.checked, 3);
    }

    const handleChangeCCNN = event => {
        setCCNN(event.target.checked);
        getValueCheckbox(event.target.checked, 4);
    }

    const handleChangeHSHC = event => {
        setHSHC(event.target.checked);
        getValueCheckbox(event.target.checked, 5);
    }

    const handleChangeHSTCT = event => {
        setTCT(event.target.checked);
        getValueCheckbox(event.target.checked, 6);
    }

    const handleChangeHSBLX = event => {
        setHSBLX(event.target.checked);
        getValueCheckbox(event.target.checked, 7);
    }

    const handleChangeCCTA = event => {
        setCCTA(event.target.checked);
        getValueCheckbox(event.target.checked, 8);
    }

    const handleChangeCCTN = event => {
        setCCTN(event.target.checked);
        getValueCheckbox(event.target.checked, 9);
    }

    const handleChangeCCTH = event => {
        setCCTH(event.target.checked);
        getValueCheckbox(event.target.checked, 10);
    }

    const handleApplyJob = () => {
        if (dataSelected.length > 0) {
            let data = {
                job: jobId,
                value: dataSelected.toString()
            }
            applyJob(data).then((res) => {
                if (res.status == 200) {
                    message(true);
                } else {
                    message(false);
                }
            });
        } else {
            message(false);
        }
        toggleModal();
    }

    return (
        <Modal isOpen={modalState} toggle={() => toggleModal()} centered>
            <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Nộp đơn cho công việc này
                    </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                    <button
                        type="button"
                        onClick={() => toggleModal()}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="hscv"
                            onChange={handleChangeHSCV}
                            checked={hscv}
                            disabled={disabledHSCV}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="hscv"
                        >
                            CV
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="btn"
                            onChange={handleChangeBTN}
                            checked={btn}
                            disabled={disabledBTN}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="btn"
                        >
                            Bằng tốt nghiệp cấp cuối cùng
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="bdtn"
                            onChange={handleChangeBDTN}
                            checked={bdtn}
                            disabled={disabledBDTN}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="bdtn"
                        >
                            Bảng điểm tốt nghiệp cấp cuối cùng
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="ccnn"
                            onChange={handleChangeCCNN}
                            checked={ccnn}
                            disabled={disabledCCNN}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="ccnn"
                        >
                            Chứng chỉ ngoại ngữ cao nhất
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="hshc"
                            onChange={handleChangeHSHC}
                            checked={hshc}
                            disabled={disabledHSHC}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="hshc"
                        >
                            Hộ chiếu
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="tct"
                            onChange={handleChangeHSTCT}
                            checked={tct}
                            disabled={disabledTCT}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="tct"
                        >
                            Visa hiện tại hoặc thẻ cư trú (nếu đang ở nước ngoài)
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="hsblx"
                            onChange={handleChangeHSBLX}
                            checked={hsblx}
                            disabled={disabledHSBLX}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="hsblx"
                        >
                            Bằng lái xe
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="ccta"
                            onChange={handleChangeCCTA}
                            checked={ccta}
                            disabled={disabledCCTA}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="ccta"
                        >
                            Chứng chỉ tiếng Anh
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="cctn"
                            onChange={handleChangeCCTN}
                            checked={cctn}
                            disabled={disabledCCTN}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="cctn"
                        >
                            Chứng chỉ tiếng Nhật
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="ccth"
                            onChange={handleChangeCCTH}
                            checked={ccth}
                            disabled={disabledCCTH}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="ccth"
                        >
                            Chứng chỉ tiếng Hàn
                        </label>
                    </div>
                </div>
                <button type="submit"
                    className="btn btn-primary w-100"
                    onClick={handleApplyJob}
                >
                    Nộp hồ sơ ứng tuyển
                </button>
            </ModalBody>
        </Modal>
    );
}

export default ApplyJobPopup;