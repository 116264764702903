import React from "react";
import { Card, CardBody, Row, Col, Placeholder } from "reactstrap";
import { Link } from "react-router-dom";

const PlaceholderLoaddingJobDescription = () => {
    return (
        <React.Fragment>
            <Col lg={8}>
                <Card className="job-detail overflow-hidden">
                    <div>
                        <Placeholder animation="glow">
                            <Placeholder className="img-fluid" style={{ height: 250 }} color="secondary" size="lg" xs={12} />
                        </Placeholder>
                    </div>
                    <CardBody className="p-4">
                        <div>
                            <Row>
                                <Col md={8}>
                                    <Placeholder animation="glow">
                                        <Placeholder className="mb-1" color="secondary" size="lg" xs={12} />
                                    </Placeholder>
                                </Col>
                                <Col lg={4}>
                                    <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                                        <li className="list-inline-item">
                                            <div className="favorite-icon">
                                                <Link to="#">
                                                    <i className="uil uil-heart-alt"></i>
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className="favorite-icon">
                                                <Link to="#">
                                                    <i className="uil uil-setting"></i>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4">
                            <Row className="g-2">
                                <Col lg={3}>
                                    <div className="border rounded-start p-3">
                                        <Placeholder animation="glow">
                                            <Placeholder color="secondary" size="lg" xs={12} />
                                        </Placeholder>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="border p-3">
                                        <Placeholder animation="glow">
                                            <Placeholder color="secondary" size="lg" xs={12} />
                                        </Placeholder>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="border p-3">
                                        <Placeholder animation="glow">
                                            <Placeholder color="secondary" size="lg" xs={12} />
                                        </Placeholder>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="border rounded-end p-3">
                                        <Placeholder animation="glow">
                                            <Placeholder color="secondary" size="lg" xs={12} />
                                        </Placeholder>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4">
                            <div className="job-detail-desc">
                                <Placeholder animation="glow">
                                    <Placeholder style={{ height: 100 }} color="secondary" size="lg" xs={12} />
                                </Placeholder>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="job-detail-desc mt-2">
                                <Placeholder animation="glow">
                                    <Placeholder style={{ height: 100 }} color="secondary" size="lg" xs={12} />
                                </Placeholder>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
                <div className="side-bar ms-lg-4">
                    <Card className="company-profile">
                        <CardBody className="p-4">
                            <div className="text-center">
                                <Placeholder animation="glow">
                                    <Placeholder className="img-fluid rounded-3" style={{ height: 50 }} size="lg" xs={3} />
                                </Placeholder>
                                <div className="mt-4">
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-17 mb-1" size="lg" xs={8} />
                                    </Placeholder>
                                </div>
                            </div>
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-14 mb-2" size="lg" xs={8} />
                                    </Placeholder>
                                </li>
                                <li className="mt-3">
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-14 mb-2" size="lg" xs={8} />
                                    </Placeholder>
                                </li>
                                <li className="mt-3">
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-14 mb-2" size="lg" xs={8} />
                                    </Placeholder>
                                </li>
                                <li className="mt-3 "></li>
                            </ul>
                            <Row>
                                <Col lg={2}>
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-4" style={{ width: 40 }} size="lg" xs={4} />
                                    </Placeholder>
                                </Col>
                                <Col lg={2}>
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-4" style={{ width: 40 }} size="lg" xs={4} />
                                    </Placeholder>
                                </Col>
                                <Col lg={2}>
                                    <Placeholder animation="glow">
                                        <Placeholder className="fs-4" style={{ width: 40 }} size="lg" xs={4} />
                                    </Placeholder>
                                </Col>
                            </Row>
                            <div className="mt-4">
                                <Placeholder animation="glow">
                                    <Placeholder className="fs-4 w-100 rounded" style={{ height: 45 }} size="lg" xs={4} />
                                </Placeholder>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </React.Fragment>
    );
};

export default PlaceholderLoaddingJobDescription;