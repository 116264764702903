import React from "react";
import { Container, Row } from "reactstrap";
import CompanyContent from "./CompanyContent";
import Section from "./Section";

const CompanyProfile = () => {
  document.title = "Thông tin nhà tuyển dụng";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container fluid>
          <Row>
            <CompanyContent />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyProfile;
