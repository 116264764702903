import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { GetCountryList } from "../../../api/apiCountry";
import { getJobsById } from "../../../api/apiJob";
import { useTranslation } from "react-i18next";
import PlaceholderLoaddingJobDescription from "../../../common/placeholder/PlaceholderLoaddingJobDescription";

const JobDetails = () => {
  const { t } = useTranslation("translation");

  document.title = t("jobdetail.chitietcongviec");
  let { pathname } = useLocation();
  let [dataJob, setDataJob] = useState();
  let [masterList, setMasterList] = useState([]);

  useEffect(() => {
    let params = pathname.split('/');
    let jobId = params[2];

    getJobsById(jobId).then((res) => {
      let data = {
        id: res.job.id,
        userId: res.job.userId,
        banner: res.job.image,
        title: res.job.title,
        nationality: res.job.quoctich,
        jobType: res.job.jobType,
        workingType: res.job.workingType,
        gender: res.job.gender,
        experienceRequired: res.job.experienceRequired,
        catId: res.job.catId,
        catId2: res.job.catId2,
        position: res.job.position,
        workLocationAddress: res.job.workLocationAddress,
        totalWorkerNeeded: res.job.totalWorkerNeeded,
        cityId: res.job.cityId,
        salaryFrom: res.job.salaryFrom,
        salaryTo: res.job.salaryTo,
        reviewSalary: res.job.reviewSalary,
        currency: res.job.currency,
        dateNeeded: res.job.dateNeeded,
        ageFrom: res.job.ageFrom,
        ageTo: res.job.ageTo,
        visaType: res.job.visaType,
        educationLevel: res.job.educationLevel,
        candidateType: res.job.candidateType,
        bonus: res.job.bonus,
        tcAnTrua: res.job.tcAnTrua,
        tcNhaO: res.job.tcNhaO,
        tcDiLai: res.job.tcDiLai,
        tcNghiViec: res.job.tcNghiViec,
        tcKhac: res.job.tcNghiViec,
        tcKhac_Content: res.job.tcKhac_Content,
        hscv: res.job.hscv,
        hsbc: res.job.hsbc,
        hstc: res.job.hstc,
        hstct: res.job.hstct,
        hshc: res.job.hshc,
        hsblx: res.job.hsblx,
        jobDescription: res.job.jobDescription,
        jobRequired: res.job.jobRequired,
        companyName: res.company.fullName,
        phone: res.company.phone,
        address: res.company.address,
        website: res.company.website,
        avatar: res.company.avatar,
        userName: res.company.userName,
        email: res.company.email
      };
      setDataJob(data);
    });

    GetCountryList().then((result) => {
      setMasterList(result);
    });
  }, [pathname]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {dataJob ? <JobDetailsDescription data={dataJob}  masterList={masterList}/> : <PlaceholderLoaddingJobDescription />}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
