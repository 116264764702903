import React from "react";
//Company Section
import AboutUs from "../pages/ExtraPages/AboutUs";
import AboutCompany from "../pages/ExtraPages/AboutCompany";
import AboutCadidate from "../pages/ExtraPages/AboutCadidate";
import Services from "../pages/Company/Services/Services";
import Team from "../pages/Company/Team/Team";
import Pricing from "../pages/Company/Pricing/Pricing";
import PrivacyAndPolicy from "../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy";
import TermsofUse from "../pages/Company/PrivacyAndPolicy/TermsofUse";

import Faqs from "../pages/Company/Faqs/Faqs";

//Jobs Section
import JobList from "../pages/Jobs/JobList/JobList";
import JobList2 from "../pages/Jobs/JobList2/JobList2";
import JobGrid from "../pages/Jobs/JobGrid/JobGrid";
import JobGrid2 from "../pages/Jobs/JobGrid2/JobGrid2";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";
import JobsCategories from "../pages/Jobs/JobsCategories/JobsCategories";
import CreateJob from "../pages/Jobs/CreateJob/CreateJob";

//Candidate and Company Section
import CandidateList from "../pages/CandidateAndCompany/CandidateList/CandidateList";
import CandidateGrid from "../pages/CandidateAndCompany/CandidateGrid/CandidateGrid";
import CandidateDetails from "../pages/CandidateAndCompany/CandidateDetails/CandidateDetails";
import CompanyList from "../pages/CandidateAndCompany/CompanyList/CompanyList";
import CompanyDetails from "../pages/CandidateAndCompany/CompanyDetails/CompanyDetails";
import CompanyView from "../pages/CandidateAndCompany/CompanyView/CompanyView";

//Blog Section
import Blog from "../pages/Blog/Blog/Blog";
import BlogGrid from "../pages/Blog/BlogGrid/BlogGrid";
import BlogModern from "../pages/Blog/BlogModern/BlogModern";
import BlogMasonary from "../pages/Blog/BlogMasonary/BlogMasonary";
import BlogDetails from "../pages/Blog/BlogDetails/BlogDetails";
import BlogAuther from "../pages/Blog/BlogAuther/BlogAuther";

//Contacts
import Contact from "../pages/Contact/Contact";

//AuthPages
import SignIn from "../pages/ExtraPages/SignIn";
import SignUp from "../pages/ExtraPages/SignUp";
import SignUpCandidate from "../pages/ExtraPages/SignUpCandidate/SignUpCandidate";
import SignUpEmployee from "../pages/ExtraPages/SignUpEmployee/SignUpEmployee";
import SignOut from "../pages/ExtraPages/SignOut";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import ChangePassword from "../pages/ExtraPages/ChangePassword";
import ComingSoon from "../pages/ExtraPages/ComingSoon";
import Error404 from "../pages/ExtraPages/Error404";
import ActiveEmail from "../pages/ExtraPages/ActiveEmail";
import Components from "../pages/ExtraPages/Components/Components";

//profile section(User Profile)
import BookMarkJobPost from "../pages/Profile/BookMarkJobPost/BookMarkJobPost";
import ManageJobs from "../pages/Profile/ManageJobs/ManageJobs";
import BookMarkJobs from "../pages/Profile/BookMarkJobs/BookMarkJobs";
import MyProfile from "../pages/Profile/MyProfile/MyProfile";
import CompanyProfile from "../pages/Company/Profile/CompanyProfile";
import Account from "../pages/Profile/MyProfile/Account";
import JobMyApplied from "../pages/Profile/MyProfile/JobMyApplied";
import AdminPage from "../pages/Admin/Main/AdminPage";
import AdminLogin from "../pages/Admin/Login/AdminLogin";

//Home Section
const Layout1 = React.lazy(() => import('../pages/Home/Layout1/Layout1'));
const Layout2 = React.lazy(() => import('../pages/Home/Layout2/Layout2'));
const Layout3 = React.lazy(() => import('../pages/Home/Layout3/Layout3'));

//Admin Section
const CategoryAdmin = React.lazy(() => import('../pages/Admin/Category/Category'));
const CountryAdmin = React.lazy(() => import('../pages/Admin/CountryCity/CountryCity'));

const userRoutes = [
  //profile Section(User Profile)
  { path: "/bookmarkjobpost", component: <BookMarkJobPost /> },
  //{ path: "/myprofile", component: <MyProfile /> },
  { path: "/companyprofile", component: <CompanyProfile /> },
  { path: "/bookmarkjobs", component: <BookMarkJobs /> },

  //Components Section(Extra Pages)
  { path: "/components", component: <Components /> },

  //Contact
  { path: "/contact", component: <Contact /> },

  // Blog Section
  { path: "/blogauther", component: <BlogAuther /> },
  { path: "/blogdetails", component: <BlogDetails /> },
  { path: "/blogmodern", component: <BlogModern /> },
  { path: "/blogmasonary", component: <BlogMasonary /> },
  { path: "/bloggrid", component: <BlogGrid /> },
  { path: "/blog", component: <Blog /> },

  //Candidate and Company Section
  { path: "/companylist", component: <CompanyList /> },
  { path: "/candidatedetails", component: <CandidateDetails /> },
  { path: "/candidategrid", component: <CandidateGrid /> },
  { path: "/candidatelist", component: <CandidateList /> },

  //Jobs Section
  { path: "/createjob", component: <CreateJob /> },
  { path: "/jobscategories", component: <JobsCategories /> },
  { path: "/jobgrid2", component: <JobGrid2 /> },
  { path: "/jobgrid", component: <JobGrid /> },
  { path: "/joblist2", component: <JobList2 /> },
  { path: "/joblist", component: <JobList /> },
  { path: "/joblist/:countryid/:industryid", component: <JobList /> },

  //Route for keyword
  { path: "/viec-lam-nha-hang-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-khach-san-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-che-bien-thuc-pham-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-xay-dung-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-dieu-duong-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-co-khi-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-dien-tu-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-nong-nghiep-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-sip/:countryid/:industryid", component: <JobList /> },


  //Company Section
  { path: "/faqs", component: <Faqs /> },
  { path: "/privacyandpolicy", component: <PrivacyAndPolicy /> },
  { path: "/termsofservice", component: <TermsofUse /> },
  { path: "/pricing", component: <Pricing /> },
  { path: "/team", component: <Team /> },
  { path: "/services", component: <Services /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/nha-tuyen-dung", component: <AboutCompany /> },
  { path: "/ung-vien", component: <AboutCadidate /> },

  //Home Section
  { path: "/layout3", component: <Layout3 /> },
  { path: "/layout2", component: <Layout1 /> },
  { path: "/", component: <Layout2 /> },

  //ViewCompany
  { path: "/company/:username", component: <CompanyView /> },
  //ViewJob
  { path: "/job/:id", component: <JobDetails /> },
];

const adminRoutes=[
  {path: "/quanly", component: <AdminPage/>},
  {path: "/quanly/category", component: <CategoryAdmin/>},
  {path: "/quanly/country", component: <CountryAdmin/>},
];

const userRoleRoutes=[
  { path: "/myprofile", component: <MyProfile /> },
  { path: "/account", component: <Account /> },
  { path: "/myapplied", component: <JobMyApplied /> },
];

const adminRoleRoutes=[
  { path: "/companydetails", component: <CompanyDetails /> },
  { path: "/managejobs", component: <ManageJobs /> },
  //{ path: "/job/:id", component: <JobDetails /> },
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/account/emailconfirm", component: <ActiveEmail /> },
  { path: "/comingsoon", component: <ComingSoon /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/signout", component: <SignOut /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/signup/candidate", component: <SignUpCandidate /> },
  { path: "/signup/employee", component: <SignUpEmployee /> },
  { path: "/signin", component: <SignIn /> },
  { path: "/dang-nhap-admin", component: <AdminLogin /> },
  

];
export { userRoutes, authRoutes, adminRoutes, userRoleRoutes, adminRoleRoutes };