import { _url, timeout } from "../configs/url";

export async function GetCitiesList(countryid) {
    let url = _url.server + _url.apiGetListCities + '?countries=' + `${countryid}`;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function GetCountryList() {
    let url = _url.server + _url.apiMaster + '?list=country,category,language';
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

// visa

export async function GetVisa(){
    let url = _url.server + _url.apiGetVisa;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function CreateOrEditVisa(visaInfo) {
    let url = _url.server + _url.apiVisaMod;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url,{ 
        method: 'POST',
        headers: header,
        body: JSON.stringify(visaInfo)
    }).then(res => res));
}

//language

export async function GetLanguage(){
    let url = _url.server + _url.apiGetLanguage;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function CreateOrEditLanguage(languageInfo) {
    let url = _url.server + _url.apiLanguageMod;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url,{ 
        method: 'POST',
        headers: header,
        body: JSON.stringify(languageInfo)
    }).then(res => res));
}

// country
export async function GetCountry(){
    let url = _url.server + _url.apiCountryList;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function CreateOrEditCountry(countryInfo) {
    let url = _url.server + _url.apiCountryMod;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url,{ 
        method: 'POST',
        headers: header,
        body: JSON.stringify(countryInfo)
    }).then(res => res));
}

// city
export async function CreateOrEditCity(cityInfo) {
    let url = _url.server + _url.apiCityMod;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url,{ 
        method: 'POST',
        headers: header,
        body: JSON.stringify(cityInfo)
    }).then(res => res));
}