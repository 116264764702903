import React from "react";
import { Container, Row } from "reactstrap";
import CompanyViewContent from "./CompanyViewContent";
import Section from "./Section";

const CompanyView = () => {
  document.title =
    "Thông tin chi tiết";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container fluid>
          <Row>
            <CompanyViewContent />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyView;
