import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getMyApplied } from "../../../api/apiJob";
import Section from "../../../pages/Component/PageHeader";
import JobAllList from "../../../common/jobAllList/JobAllList";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import Alerts from "../../ExtraPages/Components/Alerts";
import AlertsSuccess from "../../ExtraPages/Components/AlertsSuccess";
import Spinners from "../../ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";
import PlaceholderLoaddingJob from "../../../common/placeholder/PlaceholderLoaddingJob";
import { convertExperience } from "../../../common/checkValidate/convertExperience";


const JobMyApplied = () => {

  const { t } = useTranslation("translation");


  const companyName = getStorage(STORAGE_KEYS.FULL_NAME);
  const companyImage = getStorage(STORAGE_KEYS.AVATAR);

  let [jobListing, setJobListing] = useState([]);
  let [alertNoData, setAlertNoData] = useState('');

  useEffect(() => {
    getMyApplied().then((res) => {
      const job = [];
      if (res.length) {
        res.map((item, key) => {
          job.push({
            id: item.job.id,
            companyImg: companyImage ? companyImage : ImageDefault,
            jobTitle: item.job.title,
            companyName: item.job.company ? item.job.company : companyName,
            location: item.job.workLocationAddress,
            cityId: item.job.cityId,
            catId: item.job.catId,
            fullTime: (item.jobType === 1 ? false : true),
            salary: numberFormat(item.job.salaryFrom) + ' - ' + numberFormat(item.job.salaryTo) + ' ' + item.job.currency + '/tháng',
            timing: (item.job.jobType === 1 ? "Bán thời gian" : (item.job.jobType === 0 ? "Toàn thời gian" : "Tất cả hình thức")),
            addclassNameBookmark: false,
            dateNeeded: item.job.dateNeeded ? item.job.dateNeeded : "",
            badges: [],
            experience: convertExperience(item.job.experienceRequired)
          });
        });
        setJobListing(job);
        job.length == 0 ? setAlertNoData('Không có việc làm đang ứng tuyển!') : setAlertNoData('');
      } else {
        setAlertNoData('Không có việc làm đang ứng tuyển!');
        setJobListing([]);
      }
    });
  }, []);


  return (
    <React.Fragment>
      <Section title="Việc làm đang ứng tuyển" />
      <section className="section">
        <Container>
          <Row>
            <Col lg={12}>
              {alertNoData && <div className="section-title text-center">
                <h3 className="title">{alertNoData}</h3>
              </div>}
              {(!jobListing.length && !alertNoData) ? (<PlaceholderJobFilter />) : <JobAllList jobList={jobListing} myApplied={true} />}
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default JobMyApplied;
