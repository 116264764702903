import React from "react";

const Spinners = () => {
  return (
    <React.Fragment>
      <div id="spinner-container">
        <div id="spinner-box">
          <div
            className="spinner-item spinner-grow text-success m-1"
            role="status"
          ></div>
          <div
            className="spinner-item spinner-grow text-success m-1"
            role="status"
          ></div>
          <div className="spinner-item spinner-grow text-success m-1" role="status"></div>
          <div
            className="spinner-grow text-success m-1"
            role="status"
          ></div>
          <div className="spinner-item spinner-grow text-success m-1" role="status"></div>
          <div className="spinner-item spinner-grow text-success m-1" role="status"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Spinners;
