
import React, { useLayoutEffect, useState } from "react";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage, setStorage } from "../../../common/constants/Services";
import AvatarDefaultImage from "../../../assets/images/user/img-01.jpg";
import { getAccountInfo } from "../../../api/apiAccount";


const AdminHeader = () => {

  let accessToken = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
  let avatarStorage = getStorage(STORAGE_KEYS.AVATAR);
  let fullnameStorage = getStorage(STORAGE_KEYS.FULL_NAME);
  let roleStorage = getStorage(STORAGE_KEYS.ROLE);

  let [fullname, setFullName] = useState('');
  let [avatar, setAvatar] = useState(AvatarDefaultImage);
  let [role, setRole] = useState('');


  useLayoutEffect(()=>{
    if(accessToken){
      getAccountInfo().then((result) => {
        result.fullName ? setStorage(STORAGE_KEYS.FULL_NAME, result.fullName) : setStorage('fullName');
        result.role ? setStorage(STORAGE_KEYS.ROLE, result.role) : setStorage('role');
        setFullName(result.fullName);
        setRole(result.role);
      });
    }
  }, [])

  const handleLogout = () => {
    setStorage(STORAGE_KEYS.ACCESS_TOKEN, '');
    setStorage(STORAGE_KEYS.REFRESH_TOKEN, '');
    setStorage(STORAGE_KEYS.EXPIRED_TOKEN, '');
    setStorage(STORAGE_KEYS.FULL_NAME, '');
    setStorage(STORAGE_KEYS.AVATAR, '');
    setStorage(STORAGE_KEYS.ROLE, '');
    window.location.href="/dang-nhap-admin"
  }

  return (
    <Navbar variant="dark" expanded className=" ps-0 pe-2 pb-0  mb-4">
      <Container fluid className="px-0 ">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
           
          </div>
          <Nav className="user align-items-center mt-3">
            <Dropdown as={Nav.Item} className="position-relative">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body me-2 text-dark align-items-center d-none d-lg-block text-end" style={{minWidth:'100px'}}>
                    <span className="mb-0 font-small fw-bold">{fullname}</span>
                  </div>
                  <Image src={avatar} className="user-avatar md-avatar rounded-circle" width={40} style={{minWidth:'40px'}} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown mt-2 bg-light text-end">
                {/* <Dropdown.Item className="fw-bold">
                 My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                 Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                 Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                 Support
                </Dropdown.Item>

                <Dropdown.Divider /> */}

                <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                   Đăng xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;