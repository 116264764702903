import React from "react"
import AdminSidebar from "./Sidebar";
import AdminHeader from "./Header";

const AdminLayout = ({children}) => { 

    return (
      <React.Fragment>
        <AdminSidebar />
        <main className="content">
        <AdminHeader />
        <div>{children}</div>
        </main>
      </React.Fragment>
    )
  }


export default AdminLayout;