import { Outlet, Navigate } from "react-router-dom";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage } from "../common/constants/Services";

const ProtectRouteUser = () => {
    let role = getStorage(STORAGE_KEYS.ROLE);

    return ((!role || role !== 'Employee') ? <Navigate to="/signin"/> : <Outlet/>)
};

export default ProtectRouteUser;