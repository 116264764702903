import { experienceRequiredList } from "../dropdownList/dropdownData";

export const convertExperience = (experienceRequired) => {
    let experience = '';
    if (experienceRequired === 0) {
        experience = experienceRequiredList[0].label;
    } else if (experienceRequired === 1) {
        experience = experienceRequiredList[1].label;
    } else if (experienceRequired === 2) {
        experience = experienceRequiredList[2].label;
    } else if (experienceRequired === 3) {
        experience = experienceRequiredList[3].label;
    } else if (experienceRequired === 4) {
        experience = experienceRequiredList[4].label;
    } else {
        experience = experienceRequiredList[5].label;
    };
    return experience;
}