
import React, { useState, useEffect } from "react";
import { Col, Row, Container, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import homepage from "../../assets/images/nhatuyendung/homepage.webp";
import step1 from "../../assets/images/nhatuyendung/step1.webp";
import step2 from "../../assets/images/nhatuyendung/step2.webp";
import step3 from "../../assets/images/nhatuyendung/step3.webp";
import step4 from "../../assets/images/nhatuyendung/step4.webp";
import step5 from "../../assets/images/nhatuyendung/step5.webp";
import description1 from "../../assets/images/howitwork/description1.webp";
import description2 from "../../assets/images/howitwork/description2.webp";
import description3 from "../../assets/images/howitwork/description3.webp";
import description4 from "../../assets/images/howitwork/description4.webp";
import description5 from "../../assets/images/howitwork/description5.webp";
import description6 from "../../assets/images/howitwork/description6.webp";
import register from "../../assets/images/nhatuyendung/register.webp";
import './AboutCompany.css';

const AboutCompany = () => {
    document.title = "TopJob360 - Tuyển dụng";
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    const handleRegister = () => {
        navigate("/signup/candidate");
    }

    return (
        <React.Fragment>
            <div className="aboutus">
                <div className="introduce" style={{ backgroundImage: `url(${homepage})` }}>
                </div>
                <Container>
                    <div data-aos="fade-down">
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <div className="text-center" style={{ marginLeft: '24px' }}>
                                    <div className="video-embed video-introduce">
                                        <iframe
                                            src="https://www.youtube.com/embed/YNfF1-wcd6A?si=z1sHl6Bm6o1kNsBN&rel=0"
                                            title="YouTube video player" frameborder="0" loading="lazy"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowfullScreen></iframe>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="company">
                        <div className="company-text">
                            <div data-aos="fade-down">
                                <div className="title-slogan">
                                    <p>Thu hút nhân tài quốc tế với <span style={{ color: "#027F52" }}>TopJob360</span></p>
                                </div>
                                <p>Bạn đang tìm kiếm những ứng viên xuất sắc từ khắp nơi trên thế giới để gia nhập đội ngũ nhân viên của mình? Hãy để <span style={{ color: "#027F52" }}>TopJob360</span> giúp bạn!</p>
                                <p><span style={{ color: "#027F52" }}>TopJob360</span> là nền tảng tuyển dụng quốc tế hàng đầu, kết nối các công ty như bạn với những ứng viên tiềm năng nhất từ mọi quốc gia, đặc biệt là người Việt Nam.
                                    Chúng tôi cung cấp đa dạng các giải pháp tuyển dụng hiệu quả, giúp bạn dễ dàng tìm kiếm và thu hút nhân tài phù hợp với nhu cầu của doanh nghiệp.
                                </p>
                            </div>
                            <Container>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">Tiếp cận nguồn ứng viên tiềm năng</h5>
                                            <div className="description-list">
                                                <p>Truy cập vào kho dữ liệu khổng lồ với hàng triệu hồ sơ ứng tuyển từ các chuyên gia trình độ cao trên toàn cầu.</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description1} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description2} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">Tìm kiếm chính xác</h5>
                                            <div className="description-list">
                                                <p>Sử dụng công nghệ tiên tiến để lọc ứng viên theo nhiều tiêu chí, bao gồm kỹ năng, kinh nghiệm, ngôn ngữ, v.v., giúp bạn nhanh chóng tìm kiếm được những ứng viên phù hợp nhất.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">Tiết kiệm thời gian và chi phí</h5>
                                            <div className="description-list">
                                                <p>Tự động hóa các quy trình tuyển dụng, giảm thiểu thời gian và chi phí tuyển dụng, đồng thời nâng cao hiệu quả tuyển dụng.</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description3} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description4} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">Đánh giá ứng viên toàn diện</h5>
                                            <div className="description-list">
                                                <p>Sử dụng các công cụ đánh giá năng lực và tiềm năng ứng viên đa dạng, giúp bạn đưa ra quyết định tuyển dụng sáng suốt.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">Quản lý quy trình tuyển dụng hiệu quả</h5>
                                            <div className="description-list">
                                                <p>Theo dõi tiến độ tuyển dụng theo từng giai đoạn, quản lý hồ sơ ứng viên và trao đổi với ứng viên dễ dàng trên nền tảng trực tuyến.</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description5} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description6} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">Ngoài ra, <span style={{ color: "#027F52" }}>TopJob360</span> còn mang đến cho bạn</h5>
                                            <div className="description-list">
                                                <p>Thương hiệu tuyển dụng uy tín: Nâng cao hình ảnh thương hiệu của doanh nghiệp và thu hút những ứng viên tiềm năng nhất bằng cách hỗ trợ tạo homepage miễn phí.</p>
                                            </div>
                                            <div className="description-list">
                                                <p>Tư vấn chuyên nghiệp: Đội ngũ chuyên viên tư vấn giàu kinh nghiệm luôn sẵn sàng hỗ trợ bạn trong suốt quá trình tuyển dụng, từ việc xây dựng chiến lược tuyển dụng đến đánh giá ứng viên.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="section-title me-5">
                            <h3 className="title">{t("homepage.cachhoatdong")}</h3>
                            <Nav className="process-menu  flex-column nav-pills">
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">1</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("homepage.taotaikhoanungvien")}</h5>
                                            <p className="text-muted mb-0">
                                                Click vào nút "Đăng ký" với tư cách nhà tuyển dụng.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Nhập đầy đủ thông tin như tên công ty, địa chỉ, website, v.v.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Tạo tài khoản và đặt mật khẩu.
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">2</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Đăng tin tuyển dụng</h5>
                                            <p className="text-muted mb-0">
                                                Đăng nhập vào <span style={{ color: "#027F52" }}>TopJob360</span>. Vào menu "Danh sách công việc".
                                            </p>
                                            <p className="text-muted mb-0">
                                                Bấm button "Thêm". Nhập đầy đủ thông tin tuyển dụng.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Upload hình ảnh và video (nếu có). Đăng tin.
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "3" })}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">3</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Tìm kiếm ứng viên</h5>
                                            <p className="text-muted mb-0">
                                                Sử dụng thanh tìm kiếm để tìm kiếm ứng viên theo các tiêu chí.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Sử dụng các bộ lọc để thu hẹp phạm vi tìm kiếm.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Xem hồ sơ ứng viên và liên hệ trực tiếp với ứng viên phù hợp.
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "4" })}
                                    onClick={() => {
                                        tabChange("4");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">4</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Quản lý hồ sơ ứng viên</h5>
                                            <p className="text-muted mb-0">
                                                Xem danh sách hồ sơ ứng viên đã ứng tuyển vào tin tuyển dụng của bạn.
                                            </p>
                                            <p className="text-muted mb-0">
                                                Gửi email thông báo kết quả ứng tuyển cho ứng viên.
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "5" })}
                                    onClick={() => {
                                        tabChange("5");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">5</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">Các tính năng khác</h5>
                                            <p className="text-muted mb-0">
                                                Đăng ký sử dụng gói tài khoản trả phí để mở rộng tính năng
                                            </p>
                                            <p className="text-muted mb-0">
                                                Tạo thương hiệu nhà tuyển dụng
                                            </p>
                                            <p className="text-muted mb-0">
                                                Quản lý quy trình tuyển dụng
                                            </p>
                                            <p className="text-muted mb-0">
                                                Báo cáo thống kê
                                            </p>
                                            <p className="text-muted mb-0">
                                                Phản hồi nhanh chóng các ứng viên đã ứng tuyển.
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </Nav>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <img src={step1} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="2">
                                <img src={step2} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="3">
                                <img src={step3} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="4">
                                <img src={step4} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="5">
                                <img src={step5} alt="" className="img-fluid" />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={12}>
                        <div className="section-title text-center" data-aos="fade-up">
                            <h3 className="title">Chúc bạn thành công trong việc tuyển dụng nhân tài với <span style={{ color: "#027F52" }}>TopJob360</span>!</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
            <button onClick={handleRegister}>
              <img src={register} alt="" className="button-register" />
            </button>
        </React.Fragment>
    )
}



export default AboutCompany;
