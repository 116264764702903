
import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import HoSoUngVien from "../../assets/images/nhatuyendung/step2.webp";
import ViecLamDaDang from "../../assets/images/nhatuyendung/step4.webp";
import TimKiemThongMinh from "../../assets/images/nhatuyendung/step3.webp";
import CongDong from "../../assets/images/nhatuyendung/step5.webp";
import Banner from "../../assets/images/about/banner.webp"
import './AboutCompany.css';

const AboutUs = () => {
    document.title = "TopJob360 - Giới thiệu";

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <React.Fragment>
            <div className="aboutus">
                <div className="introduce" style={{ backgroundImage: `url(${Banner})` }}>
                </div>
                <Container>
                    <div className="company">
                        <div data-aos="fade-down">
                            <div className="title-slogan">
                                <p><span style={{ color: "#027F52" }}>TopJob360</span> là website chuyên về tuyển dụng việc làm hấp dẫn ở nước ngoài</p>
                            </div>
                        </div>
                    </div>
                </Container>
                <section className="section bg-light">
                    <Container>
                        <Row className="justify-content-center" data-aos="fade-down">
                            <Col lg={6}>
                                <div className="section-title text-center mb-5">
                                    <h5 className="title mb-3">Những điều làm nên sự khác biệt của <span style={{ color: "#027F52" }}>TopJob360</span></h5>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center" data-aos="fade-down">
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={HoSoUngVien} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">Hồ sơ ứng viên chuyên nghiệp</h5>
                                        </Link>
                                        <p className="text-muted"><span style={{ color: "#027F52" }}>TopJob360</span> cung cấp các công cụ giúp ứng viên xây dựng hồ sơ cá nhân ấn tượng, nổi bật với nhà tuyển dụng, hỗ trợ đa ngôn ngữ để công ty nước ngoài có thể hiểu CV của ứng viên VN nhanh nhất.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={ViecLamDaDang} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">Việc làm đa dạng</h5>
                                        </Link>
                                        <p className="text-muted">Hàng ngàn việc làm từ các công ty lớn nhỏ, trong nhiều lĩnh vực khác nhau trên toàn thế giới được cập nhật liên tục trên <span style={{ color: "#027F52" }}>TopJob360</span>.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={TimKiemThongMinh} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">Tìm kiếm thông minh</h5>
                                        </Link>
                                        <p className="text-muted">Hệ thống tìm kiếm tiên tiến giúp ứng viên tìm được công việc phù hợp một cách nhanh chóng và chính xác.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={6}></Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={HoSoUngVien} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">Ứng tuyển dễ dàng</h5>
                                        </Link>
                                        <p className="text-muted">Chỉ với vài cú click, ứng viên có thể gửi hồ sơ ứng tuyển trực tiếp đến nhà tuyển dụng.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={CongDong} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">Cộng đồng người dùng lớn mạnh</h5>
                                        </Link>
                                        <p className="text-muted"><span style={{ color: "#027F52" }}>TopJob360</span> có một cộng đồng người dùng đông đảo, tạo điều kiện cho ứng viên và nhà tuyển dụng tương tác và kết nối.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={6}></Col>
                        </Row>
                    </Container>
                </section>
                <section className="section overflow-hidden">
                    <Container>
                        <div data-aos="fade-down" data-aos-duration="1000">
                            <Row>
                                <div className="section-title text-center mb-5">
                                    <h2 className="title mb-4">Lợi ích khi sử dụng <span style={{ color: "#027F52" }}>TopJob360</span></h2>
                                </div>
                                <Col lg={6}>
                                    <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                        <h5 className="title mb-2">Đối với ứng viên</h5>
                                        <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                                            <li> Tăng cơ hội tìm được việc làm phù hợp ở nước ngoài.</li>
                                            <li> Tiết kiệm thời gian và công sức.</li>
                                            <li> Kết nối với các nhà tuyển dụng uy tín trên toàn thế giới.</li>
                                            <li> Nâng cao kỹ năng tìm việc phù hợp kỹ năng và trình độ ngoại ngữ.</li>
                                            <li> Hỗ trợ visa, đào tạo để bạn có thể làm việc tốt ở nước ngoài.</li>
                                            <li> Cộng đồng <span style={{ color: "#027F52" }}>TopJob360</span> ở nước ngoài có thể hỗ trợ bạn khi gặp khó khăn.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                        <h5 className="title mb-2">Đối với nhà tuyển dụng</h5>
                                        <ul className="list-unstyled about-list text-muted">
                                            <li> Tiếp cận nguồn ứng viên chất lượng cao ở VN.</li>
                                            <li> Tiết kiệm chi phí tuyển dụng.</li>
                                            <li> Tìm kiếm ứng viên nhanh chóng và hiệu quả.</li>
                                            <li> Quản lý quy trình tuyển dụng dễ dàng.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <Container>
                    <div data-aos="fade-down">
                        <div className="footer-about-slogan mb-5">
                            <h2>Thế giới rộng lớn, cơ hội vô cùng, hãy để TopJob360 kết nối bạn với những cơ hội việc làm quốc tế ở những công ty uy tín toàn cầu, cùng nhau chinh phục thành công.</h2>
                        </div>
                    </div>
                    <div data-aos="fade-up">
                        <div className="section-title text-center mb-5" style={{ color: '#027F52' }}>
                            <h5>Bạn đã sẵn sàng khám phá thế giới việc làm với TopJob360 chưa?</h5>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AboutUs;
