import React, { useState, useEffect, useRef } from "react";
import { checkEmailFormat } from "../../common/checkValidate/checkEmail";
import { changePassword } from "../../api/apiAccount";


import resetPasswordImage from "../../assets/images/auth/reset-password.webp";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Spinners from "../../pages/ExtraPages/Components/Spinners";
import Alerts from "../../pages/ExtraPages/Components/Alerts";
import { MESSAGE_VALIDATE } from "../../common/constants/Messages";

const ChangePassword = () => {
  document.title =
    "Đổi mật khẩu";

  const logo = 'https://wolrdjobstorage.blob.core.windows.net/assets/top_logo.png';
  let [email, setEmail] = useState('');
  let [oldPassword, setOldPassword] = useState('');
  let [oldpasswordtype, setOldPasswordType] = useState('password');
  let [newPassword, setNewPassword] = useState('');
  let [newpasswordtype, setNewPasswordType] = useState('password');
  let [valid, setValid] = useState(true);

  let [emailErr, setEmailErr] = useState('');
  let [oldPasswordErr, setOldPasswordErr] = useState('');
  let [newPasswordErr, setNewPasswordErr] = useState('');
  let [loading, setLoading] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);

  const didMount = useRef(false);

  const navigate = useNavigate();

  const handleEmail = event => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('email');
  }, [email]);

  const handleOldPassword = event => {
    setOldPassword(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('oldPassword');
  }, [oldPassword]);

  const handleShowOldPassword = () => {
    oldpasswordtype === 'password' ? setOldPasswordType('text') : setOldPasswordType('password');
  }

  const handleNewPassword = event => {
    setNewPassword(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('newPassword');
  }, [newPassword]);

  const handleShowNewPassword = () => {
    newpasswordtype === 'password' ? setNewPasswordType('text') : setNewPasswordType('password');
  }

  const handleRequest = () => {
    setValid(true);
    setLoading(true);
    setAlertDanger(false);
    changePassword(email, oldPassword, newPassword).then((res) => {
      if (res.status === 200) {
        navigate("/");
      } else {
        setAlertDanger(true);
      }
      setLoading(false);
    });
  }

  const checkValidate = (value) => {
    let checkEmail = true;
    let checkOldPassWord = true;
    let checkNewPassWord = true;
    if (value === 'email') {
      if (!checkEmailFormat(email)) {
        (!oldPassword || oldPassword === newPassword) ? checkOldPassWord = true : checkOldPassWord = false;
        (!newPassword || oldPassword === newPassword) ? checkNewPassWord = true : checkNewPassWord = false;
        setEmailErr('Định dạng Email chưa chính xác');
        checkEmail = true;
      } else {
        setEmailErr('');
        checkEmail = false;
      }
    }
    if (value === 'oldPassword') {
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      (!newPassword || oldPassword === newPassword) ? checkNewPassWord = true : checkNewPassWord = false;
      if (!oldPassword) {
        setOldPasswordErr('Vui lòng nhập mật khẩu cũ');
        checkOldPassWord = true;
      } else {
        setOldPasswordErr('');
        checkOldPassWord = false;
      }
    }
    if (value === 'newPassword') {
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      (!oldPassword || oldPassword === newPassword) ? checkOldPassWord = true : checkOldPassWord = false;
      if (!newPassword) {
        setNewPasswordErr('Vui lòng nhập mật khẩu mới');
        checkNewPassWord = true;
      } else {
        setNewPasswordErr('');
        checkNewPassWord = false;
      }
    }
    (checkEmail || checkOldPassWord || checkNewPassWord) ? setValid(true) : setValid(false);
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={logo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                src={logo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          {loading && <Spinners />}
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-center mb-4">
                              <h5>Đổi mật khẩu đăng nhập</h5>
                            </div>
                            <div className="auth-form text-white">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}
                                Nhập Email của bạn và hướng dẫn sẽ được gửi
                                cho bạn!{" "}
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                  Email
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Nhập email"
                                  onChange={handleEmail}
                                  value={email}
                                />
                                <label style={{ color: 'red', fontSize: 14 }}>{emailErr}</label>
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                  Mật khẩu cũ
                                </label>
                                <Input
                                  type={oldpasswordtype}
                                  className="form-control"
                                  id="oldPasswordInput"
                                  placeholder="Nhập mật khẩu cũ"
                                  onChange={handleOldPassword}
                                  value={oldPassword}
                                />
                                <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10 }} onClick={handleShowOldPassword}>
                                  {oldpasswordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                </p>
                                <label style={{ color: 'red', fontSize: 14 }}>{oldPasswordErr}</label>
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                  Mật khẩu mới
                                </label>
                                <Input
                                  type={newpasswordtype}
                                  className="form-control"
                                  id="newPasswordInput"
                                  placeholder="Nhập mật khẩu mới"
                                  onChange={handleNewPassword}
                                  value={newPassword}
                                />
                                <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10 }} onClick={handleShowNewPassword}>
                                  {newpasswordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                </p>
                                <label style={{ color: 'red', fontSize: 14 }}>{newPasswordErr}</label>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  className="btn btn-white w-100"
                                  onClick={handleRequest}
                                  disabled={valid}
                                >
                                  Đổi mật khẩu
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                      {alertdanger && <Alerts message={MESSAGE_VALIDATE.singup_invalid} />}
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
