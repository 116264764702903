import { Outlet, Navigate } from "react-router-dom";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage } from "../common/constants/Services";

const ProtectRouteAdmin = () => {
    let role = getStorage(STORAGE_KEYS.ROLE);

    return ((!role || role !== 'Candidate') ? <Navigate to="/error404"/> : <Outlet/>)
};

export default ProtectRouteAdmin;