import React from "react";
import PageHeader from "../../Component/PageHeader"
import { useTranslation } from "react-i18next";
import { APP_CONST } from "../../../common/constants/Constants";
import { Container, Row, Col } from "reactstrap";

const PrivacyAndPolicy = () => {
  const { t } = useTranslation("translation");
  let title = t("privacy.title");
  document.title = title+" - " + APP_CONST.APP_NAME;
  return (
    <React.Fragment>
      <PageHeader title={title} />
      <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
          <Col lg={12}>
            <p>{t("privacy.l1")}</p> 
            <h4 className="mb-3 mt-5">{t("privacy.l2")}</h4>
            <p>{t("privacy.l3")}</p>
            <p>{t("privacy.l4")}</p>
            <p>{t("privacy.l5")}</p>
            <p>{t("privacy.l6")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l7")}</h4>
            <p>{t("privacy.l8")}</p>
            <p>{t("privacy.l9")}</p>
            <p>{t("privacy.l10")}</p>
            <p>{t("privacy.l11")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l12")}</h4>
            <p>{t("privacy.l13")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l14")}</h4>
            <p>{t("privacy.l15")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l16")}</h4>
            <p>{t("privacy.l17")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l18")}</h4>
            <p>{t("privacy.l19")}</p>
            <p>{t("privacy.l20")}</p>
            <p>{t("privacy.l21")}</p>
            <h4 className="mb-3 mt-5">{t("privacy.l22")}</h4>
            <p>{t("privacy.l23")}</p>

          </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
    </React.Fragment>
  );
};
export default PrivacyAndPolicy;
