import React from "react";
import { Card, CardBody, Row, Col, Placeholder, UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";

const PlaceholderLoaddingJob = () => {
    return (
        <React.Fragment>
            <Card className="job-box card mt-4">
                <CardBody className="p-4">
                    <Row>
                        <Col lg={1}>
                            <Placeholder animation="glow">
                                <Placeholder className="rounded-3" style={{ height: 50 }} color="secondary" size="lg" xs={12} />
                            </Placeholder>
                        </Col>
                        <Col lg={8}>
                            <div className="mt-3 mt-lg-0">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                    <Placeholder color="secondary" xs={12} />
                                    <div className="mt-2">
                                        <Placeholder className="fs-13 mt-1 mx-1 rounded-2" color="secondary" xs={2} />
                                    </div>
                                </Placeholder>
                            </div>
                        </Col>
                        <Col lg={3} className="align-self-center">
                            <ul className="list-inline mt-3 mb-0">
                                <li
                                    className="list-inline-item"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                >
                                    <Placeholder animation="glow">
                                        <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-18" color="secondary" xs={2} />
                                    </Placeholder>
                                </li>
                                <UncontrolledButtonDropdown direction="down">
                                    <DropdownToggle caret
                                        type="button"
                                        tag="a"
                                    >
                                        <Placeholder animation="glow">
                                            <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-20" color="secondary" xs={2} />
                                        </Placeholder>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className="job-box card mt-4">
                <CardBody className="p-4">
                    <Row>
                        <Col lg={1}>
                            <Placeholder animation="glow">
                                <Placeholder className="rounded-3" style={{ height: 50 }} color="secondary" size="lg" xs={12} />
                            </Placeholder>
                        </Col>
                        <Col lg={8}>
                            <div className="mt-3 mt-lg-0">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                    <Placeholder color="secondary" xs={12} />
                                    <div className="mt-2">
                                        <Placeholder className="fs-13 mt-1 mx-1 rounded-2" color="secondary" xs={2} />
                                    </div>
                                </Placeholder>
                            </div>
                        </Col>
                        <Col lg={3} className="align-self-center">
                            <ul className="list-inline mt-3 mb-0">
                                <li
                                    className="list-inline-item"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                >
                                    <Placeholder animation="glow">
                                        <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-18" color="secondary" xs={2} />
                                    </Placeholder>
                                </li>
                                <UncontrolledButtonDropdown direction="down">
                                    <DropdownToggle caret
                                        type="button"
                                        tag="a"
                                    >
                                        <Placeholder animation="glow">
                                            <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-20" color="secondary" xs={2} />
                                        </Placeholder>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className="job-box card mt-4">
                <CardBody className="p-4">
                    <Row>
                        <Col lg={1}>
                            <Placeholder animation="glow">
                                <Placeholder className="rounded-3" style={{ height: 50 }} color="secondary" size="lg" xs={12} />
                            </Placeholder>
                        </Col>
                        <Col lg={8}>
                            <div className="mt-3 mt-lg-0">
                                <Placeholder animation="glow">
                                    <Placeholder color="secondary" xs={12} />
                                    <Placeholder color="secondary" xs={12} />
                                    <div className="mt-2">
                                        <Placeholder className="fs-13 mt-1 mx-1 rounded-2" color="secondary" xs={2} />
                                    </div>
                                </Placeholder>
                            </div>
                        </Col>
                        <Col lg={3} className="align-self-center">
                            <ul className="list-inline mt-3 mb-0">
                                <li
                                    className="list-inline-item"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                >
                                    <Placeholder animation="glow">
                                        <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-18" color="secondary" xs={2} />
                                    </Placeholder>
                                </li>
                                <UncontrolledButtonDropdown direction="down">
                                    <DropdownToggle caret
                                        type="button"
                                        tag="a"
                                    >
                                        <Placeholder animation="glow">
                                            <Placeholder className="avatar-sm d-inline-block text-center rounded-circle fs-20" color="secondary" xs={2} />
                                        </Placeholder>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default PlaceholderLoaddingJob;