import { _url, timeout } from "../configs/url";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage } from "../common/constants/Services";

export async function refreshToken(token) {
    let url = _url.server + _url.apiRefreshToken;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    let data = {
        refreshToken: token
    }
    return timeout(fetch(url,{ 
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
    }).then(res => res.json()));
}

export async function originalGetRequest(url, method) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let apiUrl = url;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    return timeout(fetch(apiUrl,{ 
        method: method,
        headers: header,
    }).then(res => res.json()));
}

export async function originalPostRequestJson(url, data, method) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let apiUrl = url;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    return timeout(fetch(apiUrl,{ 
        method: method,
        headers: header,
        body: JSON.stringify(data)
    }).then(res => res.json()));
}

export async function originalPostRequest(url, data, method) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let apiUrl = url;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    return timeout(fetch(apiUrl,{ 
        method: method,
        headers: header,
        body: JSON.stringify(data)
    }).then(res => res));
}