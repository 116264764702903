import React from 'react'
import { Row, Col } from 'reactstrap';
import { Icon } from '@iconify/react';

const AdminPage = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>SL ỨNG VIÊN</p>
            <div className='d-flex'>
              <Icon icon="mdi:user-outline" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>SL NHÀ TUYỂN DỤNG</p>
            <div className='d-flex'>
              <Icon icon="grommet-icons:user-manager" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>NGÀNH NGHỀ</p>
            <div className='d-flex'>
              <Icon icon="tabler:zoom" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>QUỐC GIA</p>
            <div className='d-flex'>
              <Icon icon="uiw:global" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>NGÔN NGỮ</p>
            <div className='d-flex'>
              <Icon icon="fluent:book-32-regular" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

        <Col lg={2}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', height: '120px', padding: '12px', marginBottom: '16px' }}>
            <p>VISA</p>
            <div className='d-flex'>
              <Icon icon="icons8:visa" style={{ fontSize: '24px' }} />
              <h4 className='ms-2 text-primary'>1000</h4>
            </div>
          </div>
        </Col>

      </Row>


    </React.Fragment>
  )
}

export default AdminPage