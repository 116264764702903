import { Font, Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { certificateOfEligibilityList } from "../../../../../common/dropdownList/dropdownData";
import fontNotoSansRegular from "../../../../../assets/fonts/NotoSansRegular.otf";
import fontNotoSansRegularItalic from "../../../../../assets/fonts/NotoSansRegularItalic.otf";
import fontNotoSansBold from "../../../../../assets/fonts/NotoSansBold.otf";
import fontNotoSansBoldItalic from "../../../../../assets/fonts/NotoSansBoldItalic.otf";

const TemplateBasic1 = (props) => {
    const { t } = useTranslation("translation");
    Font.register({ family: "NotoSansRegular", src: fontNotoSansRegular });
    Font.register({ family: "NotoSansRegularItalic", src: fontNotoSansRegularItalic });
    Font.register({ family: "NotoSansBold", src: fontNotoSansBold });
    Font.register({ family: "NotoSansBoldItalic", src: fontNotoSansBoldItalic });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF",
            fontFamily: "NotoSansRegular"
        },
        image: {
            height: 150,
            width: 150,
            paddingLeft: 20,
            paddingTop: 20
        },
        container: {
            display: "flex",
            flexDirection: "row",
            padding: 5
        },
        fullname: {
            paddingTop: 30,
            paddingLeft: 20,
            textAlign: "center",
            color: "#008000",
            fontSize: 28
        },
        titleProfile: {
            width: 300,
            borderTop: 2,
            borderTopColor: "#008000",
            borderBottom: 2,
            borderBottomColor: "#008000",
            textAlign: "center"
        },
        bodyProfile: {
            width: 300
        },
        upperText: {
            textTransform: "uppercase",
            fontFamily: "NotoSansBold",
            fontSize: 15
        },
        contentText: {
            fontSize: 11
        },
        titleExperience: {
            width: "100%",
            borderTop: 2,
            borderTopColor: "#008000",
            borderBottom: 2,
            borderBottomColor: "#008000",
            textAlign: "left"
        },
        bodyExperience: {
            width: "100%",
            borderLeft: 2,
            borderLeftColor: "#008000",
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <Image
                        style={styles.image}
                        source={props.data.avatar}
                    />
                    <View style={styles.fullname}>
                        <Text style={{ fontFamily: "NotoSansBold" }}>{props.data.fullName}</Text>
                        <Text style={{ borderBottom: 2, width: 410 }}></Text>
                        <Text style={{ fontSize: 11, color: "black", textAlign: "left", width: 410 }}>{props.data.yourself}</Text>
                    </View>
                </View>
                {/* tile */}
                <View style={styles.container}>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>{t("template.thongtincanhan")}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>{t("template.tinhtrangsuckhoe")}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>{t("template.kynang")}</Text>
                    </View>
                </View>
                {/* body */}
                <View style={styles.container}>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>{t("template.email")}: {props.data.email}</Text>
                        <Text style={styles.contentText}>{t("template.sodienthoai")}: {props.data.phone}</Text>
                        <Text style={styles.contentText}>{t("template.gioitinh")}: {props.data.gender}</Text>
                        <Text style={styles.contentText}>{t("template.kethon")}: {props.data.marriage}</Text>
                        <Text style={styles.contentText}>{t("template.ngaysinh")}: {props.data.birthDay}</Text>
                        <Text style={styles.contentText}>{t("template.tuoi")}: {props.data.age}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>{t("template.chieucao")}: {props.data.height}</Text>
                        <Text style={styles.contentText}>{t("template.cannang")}: {props.data.weight}</Text>
                        <Text style={styles.contentText}>{t("template.nhommau")}: {props.data.bloodType}</Text>
                        <Text style={styles.contentText}>{t("template.taythuan")}: {props.data.handedness}</Text>
                        <Text style={styles.contentText}>{t("template.thiluc")}: {props.data.eyesight}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>{t("template.bangcap")}: {props.data.qualifications}</Text>
                        <Text style={styles.contentText}>{t("template.tiengnhat")}: {props.data.japaneseLevel}</Text>
                        <Text style={styles.contentText}>{t("template.tienganh")}: {props.data.englishLevel}</Text>
                        <Text style={styles.contentText}>{t("template.tiengkhac")}: {props.data.otherLevel}</Text>
                    </View>
                </View>

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>{t("template.lichsuxinvisa")}</Text>
                    </View>
                </View>
                {
                    props.data.certificateOfEligibility.value == 2 ? <View style={styles.container}>
                        <View style={{ width: 200, textAlign: "center" }}>
                            <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.thoihan")}</Text>
                        </View>
                        <View style={{ width: 400, textAlign: "center" }}>
                            <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.loaivisa")}</Text>
                        </View>
                        <View style={{ width: 300, textAlign: "center" }}>
                            <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.nuocdaxinvisa")}</Text>
                        </View>
                    </View> : <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{certificateOfEligibilityList[0].label}</Text>
                    </View>
                }
                {
                    props.data.certificateOfEligibility.value == 2 && props.data.visaHistories.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 200 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.visaFromDate} {item.visaExpDate}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 500, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.visaType}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.country}</Text>
                                </View>
                            </View>
                        )
                    })
                }

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>{t("template.kynangnganhnghe")}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.nganhnghe")}</Text>
                    </View>
                    <View style={{ width: 400, textAlign: "center" }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.chuyenmon")}</Text>
                    </View>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.kynangchuyenmon")}</Text>
                    </View>
                </View>
                {
                    props.data.userSkills.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 300 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.labelIndustry}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 500, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.labelField}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.labelSkill}</Text>
                                </View>
                            </View>
                        )
                    })
                }

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>{t("template.quatrinhhoctap")}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.thoigian")}</Text>
                    </View>
                    <View style={{ width: 400, textAlign: "center" }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.tentruong")}</Text>
                    </View>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.nganhhoc")}</Text>
                    </View>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.loaitruong")}</Text>
                    </View>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.tinhtrangtotnghiep")}</Text>
                    </View>
                </View>
                {
                    props.data.userEducations.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 200 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.enrollmentMonth} {item.graduationMonth}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 400, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.detail}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 200, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.major.label}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 200, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.typeSchool.label}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.graduateStatus.label}</Text>
                                </View>
                            </View>
                        )
                    })
                }
                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>{t("template.quatrinhlamviec")}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.thoigian")}</Text>
                    </View>
                    <View style={{ width: 400, textAlign: "center" }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>{t("template.tencongty")}</Text>
                    </View>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.chucvu")}</Text>
                    </View>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>{t("template.motacongviec")}</Text>
                    </View>
                </View>
                {
                    props.data.userWorkExps.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 200 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.startMonth} {item.endMonth}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 400, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.company}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 200, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.position.label}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.jobDesc}</Text>
                                </View>
                            </View>
                        )
                    })
                }
                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>{t("template.sothichtinhcach")}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ flexDirection: "column", width: 500, textAlign: "center" }}>
                        <Text style={styles.contentText}>{t("template.tinhcach")}: {props.data.personality}</Text>
                        <Text style={styles.contentText}>{t("template.monhocsotruong")}: {props.data.favoriteSubjects}</Text>
                    </View>
                    <View style={{ flexDirection: "column", width: 500 }}>
                        <Text style={styles.contentText}>{t("template.sotruong")}: {props.data.strongPoints}</Text>
                        <Text style={styles.contentText}>{t("template.sodoan")}: {props.data.weakPoints}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default TemplateBasic1;