import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HowItWorks from "../Home/HowItWorks";
import SectionEmployee from "../Home/Layout2/SectionEmployee";
import register from "../../assets/images/ungvien/register.webp";
import './AboutCompany.css';

const AboutCadidate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  const handleRegister = () => {
    navigate("/signup/employee");
  }

  return (
    <React.Fragment>
      <SectionEmployee />
      <HowItWorks />
      <button onClick={handleRegister}>
        <img src={register} alt="" className="button-register" />
      </button>
    </React.Fragment>
  );
};

export default AboutCadidate;
