import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMyJobs } from "../../../api/apiJob";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import ApplyJobPopup from "../../ExtraPages/Components/ApplyJobPopup";
import { convertExperience } from "../../../common/checkValidate/convertExperience";

const JobVacancyPost = (props) => {
  const { t } = useTranslation("translation");
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const companyName = getStorage(STORAGE_KEYS.FULL_NAME);
  const companyImage = getStorage(STORAGE_KEYS.AVATAR);
  const role = getStorage(STORAGE_KEYS.ROLE);

  let jobDetail = '/job/';
  let [jobVacancyPost, setJobVacancyPost] = useState([]);

  useEffect(() => {
    if (companyName && role == "Candidate") {
      getMyJobs(1).then((res) => {
        const job = [];
        if (res.data.length) {
          res.data.map((item, key) => {
            if (item.id != props.data.id && item.catId == props.data.catId) {
              job.push({
                id: item.id,
                companyImg: companyImage,
                jobDescription: item.title,
                experience: convertExperience(item.experienceRequired),
                companyName: item.company ? item.company : companyName,
                location: item.workLocationAddress,
                salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency + '/tháng',
                fullTime: (item.jobType === 1 ? false : true),
                partTime: (item.jobType === 1 ? true : false),
                timing: (item.jobType === 1 ? "Bán thời gian" : (item.jobType === 0 ? "Toàn thời gian" : "Tất cả hình thức")),
                badges: [],
              });
            }
          });
          setJobVacancyPost(job);
        }
      })
    }
  }, [props])

  return (
    <React.Fragment>
      {jobVacancyPost.map((jobVacancyPostDetails, key) => (
        <div
          key={key}
          className={
            jobVacancyPostDetails.addclassNameBookmark === true
              ? "job-box bookmark-post card mt-4"
              : "job-box card mt-4"
          }
        >
          <div className="p-4">
            <Row>
              <Col lg={1}>
                <Link to={jobDetail + jobVacancyPostDetails.id}>
                  <img
                    src={jobVacancyPostDetails.companyImg}
                    alt=""
                    className="img-fluid rounded-3"
                  />
                </Link>
              </Col>
              <Col lg={10}>
                <div className="mt-3 mt-lg-0">
                  <h5 className="fs-17 mb-1">
                    <Link to={jobDetail + jobVacancyPostDetails.id} className="text-dark">
                      {jobVacancyPostDetails.jobDescription}
                    </Link>{" "}
                    <small className="text-muted fw-normal">
                      ({jobVacancyPostDetails.experience})
                    </small>
                  </h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        {jobVacancyPostDetails.companyName}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="mdi mdi-map-marker"></i>
                        {jobVacancyPostDetails.location}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="uil uil-wallet"></i>{" "}
                        {jobVacancyPostDetails.salary}
                      </p>
                    </li>
                  </ul>
                  <div className="mt-2">
                    <span
                      className={
                        jobVacancyPostDetails.fullTime === true
                          ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                          : jobVacancyPostDetails.partTime === true
                            ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                            : jobVacancyPostDetails.freeLance === true
                              ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                              : jobVacancyPostDetails.internship === true
                                ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                : ""
                      }
                    >
                      {jobVacancyPostDetails.timing}
                    </span>
                    {(jobVacancyPostDetails.badges || []).map(
                      (badgeInner, key) => (
                        <span
                          className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                          key={key}
                        >
                          {badgeInner.badgeName}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="favorite-icon">
              <Link to="#">
                <i className="uil uil-heart-alt fs-18"></i>
              </Link>
            </div>
          </div>
          <div className="p-3 bg-light">
            <div className="row justify-content-between">
              <Col md={8}>
              </Col>

              <Col md={3}>
                <div className="text-md-end">
                  <Link
                    to=""
                    onClick={openModal}
                    className="primary-link"
                  >
                    {t("jobdetail.ungtuyen")} <i className="mdi mdi-chevron-double-right"></i>
                  </Link>
                </div>
              </Col>
            </div>
          </div>
        </div>
      ))}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <ApplyJobPopup modalState={modal} toggleModal={openModal} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobVacancyPost;
