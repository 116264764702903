import { Outlet, Navigate } from "react-router-dom";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage } from "../common/constants/Services";

const ProtectRouteManagePage = () => {
    let role = getStorage(STORAGE_KEYS.ROLE);

    return ((!role || role !== 'SystemAdmin') ? <Navigate to="/dang-nhap-admin"/> :<Outlet/> )
}

export default ProtectRouteManagePage